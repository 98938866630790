import {ObjectString} from 'gollumts-objecttype';
import {ObjectEvented} from '@/shared/utils';

export interface CallerOption {
	method?: 'GET'|'POST'|'PUT'|'PATCH'|'DELETE';
	body?: string;
	headers?: ObjectString<string>;
	withCredentials?: boolean;
	progress?: (event: ProgressEvent, callerAjaxEvent: CallerAjaxEvent) => void;
}

export interface CallerAjaxEvent {
	url: string;
	options: CallerOption,
	xhr: XMLHttpRequest;
}

export class Caller extends ObjectEvented {
	
	protected ajax(url: string, options: any): Promise<any>  {
		
		return new Promise<any>((resolve, reject) => {
			try {
				
				const event = {
					url: url,
					options: options,
					xhr:  new XMLHttpRequest(),
				} as CallerAjaxEvent;
				this.trigger('ajax-send', event);
				
				const callReject = (e) => {
					try {
						event.xhr['responseJSON'] = JSON.parse(event.xhr.responseText);
					} catch (e) {
					}
					event.xhr['error'] = e;
					this.trigger('ajax-error', event.xhr);
					this.trigger('ajax-complete', event.xhr);
					reject(event.xhr);
				};
				
				event.xhr.open(event.options.method, event.url);
				event.xhr.withCredentials = event.options.withCredentials;
				
				event.xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
				for (const name of Object.keys(event.options.headers)) {
					event.xhr.setRequestHeader(name, event.options.headers[name]);
				}
				
				event.xhr.addEventListener('load', () => {
					try {
						if (event.xhr.status >= 200 && event.xhr.status < 300) {
							event.xhr['responseJSON'] = JSON.parse(event.xhr.responseText);
							this.trigger('ajax-success', event.xhr);
							this.trigger('ajax-complete', event.xhr);
							resolve(event.xhr['responseJSON']);
						} else {
							throw new Error('Ajax response status failed');
						}
					} catch (e) {
						callReject(e);
					}
				});
				event.xhr.addEventListener('error', e => {
					callReject(e);
				});
				event.xhr.addEventListener('abort', () => {
					callReject(new Error('Abort request'));
				});
				if (options.progress) {
					event.xhr.addEventListener('progress', e => {
						options.progress(e, event);
					}, false);
				}
				
				if (typeof event.options.body === 'undefined') {
					event.xhr.send();
				} else {
					event.xhr.send(event.options.body);
				}
				
			} catch (e) {
				this.trigger('ajax-error', e);
				this.trigger('ajax-complete', e);
				reject(e);
			}
		});
	}
	
	public get(url: string, Model: any = null, query: any = null, options: any = {}, callbackBind: any|null = null): Promise<any> {
		options = options ? options :  {};
		options['method'] = 'GET';
		return this.request(url, Model, null, query, options, callbackBind);
	}
	
	public post(url: string, Model: any = null, data: any = null, query: any = null, options: any = {}, callbackBind: any|null = null): Promise<any> {
		options = options ? options :  {};
		options['method'] = 'POST';
		return this.request(url, Model, data, query, options, callbackBind);
		
	}
	
	public put(url: string, Model: any = null, data: any = null, query: any = null, options: any = {}, callbackBind: any|null = null): Promise<any> {
		options = options ? options :  {};
		options['method'] = 'PUT';
		return this.request(url, Model, data, query, options, callbackBind);
	}
	
	public patch(url: string, Model: any = null, data: any = null, query: any = null, options: any = {}, callbackBind: any|null = null): Promise<any> {
		options = options ? options :  {};
		options['method'] = 'PATCH';
		return this.request(url, Model, data, query, options, callbackBind);
	}
	
	public delete(url: string, Model: any = null, data: any = null, query: any = null, options: any = {}, callbackBind: any|null = null): Promise<any> {
		options = options ? options :  {};
		options['method'] = 'DELETE';
		return this.request(url, Model, data, query, options, callbackBind);
	}
	
	public async request(url: string, Model: any = null, data: any = null, query: any = null, options: CallerOption = {}, callbackBind: any|null = null): Promise<any> {
		
		try {
			this.trigger('request');
			
			url = url.replace(/(\{[a-z0-9]+\})/gi, key => {
				key = key.substr(1).substr(0, key.length - 2);
				return data[key].toString();
			});
			
			options = options ? options : {};
			
			url = Caller.dataToQuerystring(url, query);
			if (data && typeof data.toJSON == 'function') {
				data = data.toJSON();
			}
			options = {
				...{
					method: 'GET',
					withCredentials: true,
					headers: {},
				} as CallerOption,
				...options
			};
			if (options.method != 'GET') {
				options.body = JSON.stringify(data);
			}
			if (!options.headers['Content-Type']) {
				options.headers['Content-Type'] = 'application/json; charset=utf-8';
			}
			if (!options.headers['Accept']) {
				options.headers['Accept'] = 'application/json; charset=utf-8';
			}
			
			const json = await this.ajax(url, options)
			if (json === null) {
				return null;
			}
			
			let object = json;
			if (Model) {
				object = new Model();
				if (typeof object.fromJSON == 'function') {
					object.fromJSON(json);
				} else {
					for (const name of Object.keys(json)) {
						object[name] = json[name];
					}
				}
			}
			
			if (typeof callbackBind == 'function') {
				callbackBind(json, object);
			}
			
			this.trigger('success', object);
			this.trigger('complete');
			return object;
			
		} catch (e) {
			console.error(e);
			this.trigger('error', e);
			this.trigger('complete');
			throw e;
		}
	}
	
	private static dataToQuerystring(url: string, query: any): string {
		if (query) {
			
			let separator = url.indexOf('?') == -1 ? '?' : '&';
			if (typeof query.toJSON == 'function') {
				query = query.toJSON();
			}
			for(let key in query) {
				if ((key+'').substr(0, 1) != '_') {
					url += separator+key+'='+encodeURIComponent(query[key]);
					separator = '&';
				}
			}
			
		}
		return url;
	}
	
}

export default new Caller();
