














import {Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

@Component()
export default class InputZipCode extends Vue {
	@InOut({type: String , isVModel: true   }) private value!     : string;
	
	private preventBadChar(): void {
		this.value = this.value ? this.value.replace(/[^0-9]/gm, '') : '';
	}
}
