









































































































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Client, Token} from '@/models';

import storeClient from '@/stores/modules/client';

import InputPhone from '@/components/Input/Phone.vue';
import InputCountry from "@/components/Input/Country.vue";
import InputZipCode from "@/components/Input/ZipCode.vue";
import axios from "axios";
import storeToken from '@/stores/modules/token';
import {Stored} from "vue-stored-prop-decorator";
import storeFamCli from '@/stores/modules/famcli';

@Component({
	components: {
		InputPhone,
		InputCountry,
		InputZipCode,
	}
})
export default class CrudClientCreate extends AbstractEditVue<Client> {

	// DF: utiliser pour la création d'un client uniquement
	// en modification on passe par la light
	@Stored(() => storeToken) private token: Token;
	private step: number = 1;
	private famCliYso;
	private storeFamCli = storeFamCli;
	private save = true;
	private coordinates;

	public constructor() {
		super(
			storeClient,
			'Client modifié',
			'Client créé',
		);
	}

	protected async onSubmit(): Promise<void> {
		const {data} = await axios.get(
			`https://nominatim.openstreetmap.org/search?street=${this.item.adress1 + (this.item.adress2 === undefined ? '' : ` ${this.item.adress2}`)}&city=${this.item.ville}&country=france&postalcode=${this.item.strNewCodePostal}&format=json&limit=1`
		)
		this.coordinates = data
		if (data.length === 0) {
			const {data} = await axios.get(
				`https://nominatim.openstreetmap.org/search?&city=${this.item.ville}&country=france&postalcode=${this.item.strNewCodePostal}&format=json&limit=1`
			)
			this.coordinates = data
		}

		if (this.coordinates[0] != null) {
			this.item.dLatitude = this.coordinates[0]['lat'];
			this.item.dLongitude = this.coordinates[0]['lon'];
		}

		this.item.datecre = new Date();
		this.item.dwIDRole = 0
		await super.onSubmit();
		this.step = 1;
	}

	public async mounted(): Promise<void> {
		await super.mounted();
		await storeFamCli.dispatch('getAll');
		this.watchSave()
	}

	public async refresh(): Promise<void> {
		await super.refresh();
	}

	public watchSave() {
		this.save = !(this.item.raisonsoc && this.item.adress1 && this.item.strNewCodePostal && this.item.ville && this.item.dwFamCli);
	}

}
