export default [
	{
		path: '/planning/be',
		name: 'planning_be',
		meta: {
			title: 'Planning BE',
			granted: [ 'API_GET_PLANNING_FABRICATION' ],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/PlanningBE.vue')
	},
];
