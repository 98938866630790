export class ResultType<T> {
	public data: T[] = [];
	public total: number = 0;
	
	public static getType(): any {
		throw new Error('getType must be override');
	};
	
	public getType(): any {
		throw new Error('getType must be override');
	};
	
	public fromJSON(data): this {
		this.data  = data.data.map(el => (new (this.getType())).fromJSON(el));
		this.total = data.total;
		return this;
	}
}


export function Result<T>(clazz: any): any {
	
	const ResultConstruct: any = class extends ResultType<T> {
		
		constructor() {
			super();
		}
		
		public static getType(): any {
			return clazz;
		}
		
		public getType(): any {
			return clazz;
		}
	};
	
	return ResultConstruct;
}