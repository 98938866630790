import xhttpManager from  '../XHTTPManager';

export const XHTTPService = (entityCallback: () => any): any => {
	return (target: Object, propertyKey: string, descriptor: PropertyDescriptor) => {
		Object.defineProperty(target, propertyKey, {
			get: function () {
				return xhttpManager.get(entityCallback());
			}
		});
	};
};