export default {
	error: {
		default: {
			general: 'Une erreur est survenue',
			field_required: 'Le champs "{label}" est requis'
		},
	},
	enum: {
		buy_type: {
			coupe_rase: 'Coupe rase',
			coupe_eclairci: 'Coupe d’éclairci',
		},
		cvo:{ 
			non: 'Non',
			val_05: '0.5',
			val_03: '0.3',
		},
		payment_type: {
			at_sign: 'À signature',
			at_end: 'À la fin d’exploitation',
			deposit: 'Avec acomptes',
		},
		m3_type_price: {
			m3: 'M3',
			stere: 'Stère',
		},
		reglement_type: {
			cheque: 'Chèque',
			virement: 'Virement',
		},
		dest_type: {
			depot: 'Depôt',
			autre: 'Autre',
		},
		contrat_state: {
			WAITING: 'Attente',
			VALIDATE: 'Validé',
			REFUSED: 'Refusé',
		}
	},
};
