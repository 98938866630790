import {Client} from "@/models";

export default [
	{
		path: '/client/stats/:id',
		name: 'client-stats',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Client/ShowStats.vue'),
				class: Client
			}),
		},
		meta: {
			title: 'Statistiques client',
			granted: [ 'API_GET_CLIENT' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/client',
		name: 'clients',
		meta: {
			title: 'Liste des clients',
			granted: [ 'API_GET_COMMERCIAL' ],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Client.vue')
	},
	{
		path: '/clients/show/:id',
		name: 'client-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Client/Show.vue'),
				class: Client
			}),
			editRoute: { name: 'client-edit' },
		},
		meta: {
			title: 'Détail d\'un client',
			granted: [ 'API_GET_COMMERCIAL' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
