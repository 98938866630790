






































































import {Component, Vue} from '@/shared/component';
import {Stored} from "vue-stored-prop-decorator/index";
import {Redirect, User} from '@/models';
import {Form} from '@/shared/form';
import caller from '@/shared/caller';

import storeLoader from '@/stores/modules/loader';
import storeRedirect from '@/stores/modules/redirect';
import storeNotifier, {NotifyInterface} from '@/stores/modules/notifier';

@Component()
export default class ResetPasswordConfirmView extends Vue {

	@Stored(() => storeLoader)
	private loading!: boolean;

	private user: User = new User();
	private form: Form = new Form();

	private showPassword: boolean = false;
	private repeatPassword: string = '';
	private redirect: Redirect = null;
	
	private get repeatPasswordRules(): any[] {
		return [
			v => {
				console.log('COOL', v, this.user, v === this.user.password);
				return v === this.user.password || 'Les mots de passe ne sont pas identiques';
			}
		];
	}
	
	public async mounted(): Promise<void> {
		this.user.email = this.$route.params.email;
		this.user.password = '';
		try {
			this.redirect = await storeRedirect.dispatch('postCheckTokenResetPassword', {
				user: this.user,
				queryString: window.location.search,
			});	
		} catch (e) {
			if (e.status == 403) {
				storeNotifier.dispatch('notify', {
					type: 'error',
					message: 'Le lien est maintenant invalid'
				} as NotifyInterface);
			} else  {
				console.error(e);
				storeNotifier.dispatch('notify', {
					type: 'error',
					message: 'Une erreur est survenue'
				} as NotifyInterface);
			}
			this.$router.push({ name: 'login' });
		}
	}
	
	public async resetPassword(): Promise<void> {
		await this.form.call(async () => {
			await caller.patch(this.redirect.href, User, this.user);
			storeNotifier.dispatch('notify', {
				type: 'success',
				message: 'Mot de passe modifié avec succès'
			} as NotifyInterface);
			this.$router.push({ name: 'login' });
		});
	}
}

