import {XHTTP} from '@/shared/xhttp';
import {ExpeditionService} from '@/xhttp';
import {Address} from './Address';
import {Commercial} from "@/models";

@XHTTP(ExpeditionService, '/expeditions')
export class Expedition {

	private _id: number;
	private _array: Array<any>;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get array(): Array<any> {
		return this._array;
	}

	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set array(value: Array<any>) {
		this._array = value;
	}

	///////////////
	// Serialize //
	///////////////+

	public toJSON(): any {
		return {
			id       : this._id,
			array       : this._array
		};
	}

	public fromJSON(data: any): this {
		this._id        = data.id;
		this._array        = data.array;
		return this;
	}
}
