import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@/assets/scss/vuetify/index.scss';
import fr from 'vuetify/src/locale/fr';
import DeleteFDI from '@carbon/icons-vue/es/trash-can/16'
import SeeFDI from '@carbon/icons-vue/es/view/16'
import SeeCloseFDI from '@carbon/icons-vue/es/view--off/16'
import EditFDI from '@carbon/icons-vue/es/edit/16'
import PaidFDI from '@carbon/icons-vue/es/currency/16'
import DownloadFDI from '@carbon/icons-vue/es/download/16'
import DocumentFDI from '@carbon/icons-vue/es/document/16'
import ArrowUpFDI from '@carbon/icons-vue/es/chevron--up/16'
import UserFDI from '@carbon/icons-vue/es/user/24'
import DashboardFDI from '@carbon/icons-vue/es/workspace/24'
import ExpeditionFDI from '@carbon/icons-vue/es/group/24'
import SettingsFDI from '@carbon/icons-vue/es/settings/24'
import ExpeditionsFDI from '@carbon/icons-vue/es/identification/24'
import ClientsFDI from '@carbon/icons-vue/es/identification/24'
import ContactsFDI from '@carbon/icons-vue/es/report/24'
import LogoutFDI from '@carbon/icons-vue/es/logout/16'
import NewExpeditionClientFDI from '@/components/Icons/NewExpeditionClientFDI.vue'
import CommercialsFDI from '@carbon/icons-vue/es/store/24'
import CommercialsChildrenFDI from '@carbon/icons-vue/es/shopping--cart/24'
import NewCommercialsFDI from '@carbon/icons-vue/es/shopping--cart--plus/24'
import LivraisonsFDI from '@carbon/icons-vue/es/delivery/24'
import LivraisonAddFDI from '@carbon/icons-vue/es/delivery-truck/24'
import VentesFDI from '@carbon/icons-vue/es/finance/24'
import ListVentesFDI from '@carbon/icons-vue/es/receipt/24'
import NewVentesFDI from '@/components/Icons/NewVentes.vue'
import EssencesFDI from '@carbon/icons-vue/es/tree/24'
import LongueursFDI from '@carbon/icons-vue/es/pan--horizontal/24'
import TransportsFDI from '@carbon/icons-vue/es/delivery-truck/24'
import MarkPaidFDI from '@carbon/icons-vue/es/currency/16'

Vue.use(Vuetify);

// noinspection TypeScriptValidateTypes
export default new Vuetify({
    lang: {
      locales: { fr },
      current: 'fr',
    },
    theme: {
        themes: {
            light: {
                primary: '#e75113',
                secondary: '#7E0707',
                accent: '#c56c48',
                error: '#7E0707',
                warning: '#ff9800',
                info: '#ffc107',
                success: '#69AE3F',
				background: '#e5e5e5',
				background_menu: '#383f47',
				btn_nav: '#e75113',
				see: '#222222',
				edit: '#222222',
				delete: '#7E0707',
				download: '#222222',
				document: '#222222',
				title: '#FFFFFF',
				subtitle: '#ff763f',
				btn_bg_add: '#ff763f',
				btn_txt_add: '#FFFFFF',
				transparent: 'rgba(85,2,2,0)',
            }
        }
    },
});
