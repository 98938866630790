export default [
	{
		path: '/commande',
		name: 'commandes',
		meta: {
			title: 'Liste des commandes',
			granted: [ 'API_GET_COMMANDE' ],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Commande.vue')
	},
	{
		path: '/chiffre_affaire',
		name: 'chiffre_affaire',
		meta: {
			title: 'Facturation',
			granted: [ 'API_GET_COMMANDE' ],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/CA.vue')
	},
];
