











import {Component, Prop, Vue} from 'vue-property-decorator';
import {Form} from './Form';

@Component
export default class FormError extends Vue {
	@Prop() form: Form;
	Form = Form;
	
	public getErrors(): string[] {
		return this.form.getErrors(Form.FIELD_ROOT);
	}
}
