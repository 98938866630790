
































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Commercial} from '@/models';

import storeCommercial from '@/stores/modules/commercial';

import InputDatePicker from "@/components/Input/DatePicker.vue";

@Component({
	components: {
		InputDatePicker,
	}
})
export default class CrudCommercialEdit extends AbstractEditVue<Commercial> {

	// DF: utiliser pour la création d'un commercial uniquement
	// en modification on passe par la light
	private number: number = null;

	public constructor() {
		super(
			storeCommercial,
			'Commercial modifié',
			'Commercial créé',
		);
	}

	//	@Emit()
	//	private onNext() {
	//		if ((!this.item.address) || (this.item.address === null) )
	//		{
	//			this.item.address = this.item.name;
	//		}
	//		this.step = 2;
	//	}

	protected async onSubmit(): Promise<void> {
		this.$emit('on-submit', this.number);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
	}

	public async refresh(): Promise<void> {
		await super.refresh();
		//console.log('route commercial edit = ' + this.$route.name);
	}

}
