







































import {Emit} from 'vue-property-decorator';
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Prospection} from '@/models';

import CrudProspectionEdit from '@/components/Crud/Prospection/Edit.vue';

@Component({
	components: {
		CrudProspectionEdit,
	}
})
export default class CrudProspectionEditDialog extends Vue {
	@InOut({ type: Prospection  , isVModel: true }) private value!: Prospection;
	@InOut({ type: Boolean  , isVModel: false }) private route!: Boolean;

	@Emit()
	private onSubmit(prospection: Prospection) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
