import {XHTTP} from '@/shared/xhttp';
import {CommercialService} from "@/xhttp";
import {Address} from './Address';
import {DateHelper} from "@/shared/utils";
import {Expedition} from "@/models/Expedition";

@XHTTP(CommercialService, '/commercials')
export class Commercial {

	private _id: number;
	private _array: Array<any>;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get array(): Array<any> {
		return this._array;
	}

	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set array(value: Array<any>) {
		this._array = value;
	}

	/////////
	// Add //
	/////////


	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id       : this._id,
			array       : this._array
		};
	}

	public fromJSON(data: any): this {
		this._id        = data.id;
		this._array        = data.array;
		return this;
	}
}
