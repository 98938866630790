import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {Redirect, User} from '@/models';
import {XHTTPService} from '@/shared/xhttp';
import {RedirectService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

class RedirectState {
}

class RedirectStore {
	
	@XHTTPService(() => Redirect)
	private redirectService: RedirectService;
	
	public state: RedirectState = new RedirectState();
	
	public mutations = {
	};
	
	public actions = {
		postCheckTokenResetPassword(context: ActionContext<RedirectState, any>, { user, queryString }: { user: User, queryString: string }): Promise<Redirect> {
			return instance.redirectService.postCheckTokenResetPassword(user, queryString);
		},
	};
}
const instance = new RedirectStore();
const storeRedirect = new Vuex.Store(instance);
store.registerModule('redirect', storeRedirect);
export default storeRedirect;
