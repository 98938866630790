import {XHTTP} from '@/shared/xhttp';
import {ProspectionService} from "@/xhttp";
import {Address} from './Address';
import {DateHelper} from "@/shared/utils";
import {Expedition} from "@/models/Expedition";

@XHTTP(ProspectionService, '/prospections')
export class Prospection {

	private _id: number;
	private _dtDateVisite: Date = new Date();
	private _dwIDClient: number;
	private _dwIDCommercial: string;
	private _ynProchaineVisite: boolean = false;
	private _dtDateProchaineVisite: Date;
	private _dwIDPotentiel: string;
	private _ynConsultation: boolean = false;
	private _ynConsultationPromise: boolean = false;
	private _strRemarques: string;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get dtDateVisite(): Date {
		return this._dtDateVisite;
	}

	public get dwIDClient(): number {
		return this._dwIDClient;
	}

	public get dwIDCommercial(): string {
		return this._dwIDCommercial;
	}

	public get ynProchaineVisite(): boolean {
		return this._ynProchaineVisite;
	}

	public get dtDateProchaineVisite(): Date {
		return this._dtDateProchaineVisite;
	}

	public get dwIDPotentiel(): string {
		return this._dwIDPotentiel;
	}

	public get ynConsultation(): boolean {
		return this._ynConsultation;
	}

	public get ynConsultationPromise(): boolean {
		return this._ynConsultationPromise;
	}

	public get strRemarques(): string {
		return this._strRemarques;
	}

	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set dtDateVisite(value: Date) {
		this._dtDateVisite = value;
	}

	public set dwIDClient(value: number) {
		this._dwIDClient = value;
	}

	public set dwIDCommercial(value: string) {
		this._dwIDCommercial = value;
	}

	public set ynProchaineVisite(value: boolean) {
		this._ynProchaineVisite = value;
	}

	public set dtDateProchaineVisite(value: Date) {
		this._dtDateProchaineVisite = value;
	}

	public set dwIDPotentiel(value: string) {
		this._dwIDPotentiel = value;
	}

	public set ynConsultation(value: boolean) {
		this._ynConsultation = value;
	}

	public set ynConsultationPromise(value: boolean) {
		this._ynConsultationPromise = value;
	}

	public set strRemarques(value: string) {
		this._strRemarques = value;
	}

	/////////
	// Add //
	/////////


	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			dwIDProspection       : this._id,
			dtDateVisite    : this._dtDateVisite ? DateHelper.toLocalizedString(this._dtDateVisite) : null,
			dwIDClient       : this._dwIDClient,
			dwIDCommercial       : parseInt(this._dwIDCommercial),
			ynProchaineVisite       : this._ynProchaineVisite,
			dtDateProchaineVisite    : this._dtDateProchaineVisite ? DateHelper.toLocalizedString(this._dtDateProchaineVisite) : null,
			dwIDPotentiel       : parseInt(this._dwIDPotentiel),
			ynConsultation       : this._ynConsultation,
			ynConsultationPromise       : this._ynConsultationPromise,
			strRemarques       : this._strRemarques,
		};
	}

	public fromJSON(data: any): this {
		this._id        = data.dwIDProspection;
		this._dtDateVisite         = data.dtDateVisite ? DateHelper.fromLocalizedString(data.dtDateVisite) : null;
		this._dwIDClient        = data.dwIDClient;
		this._dwIDCommercial        = data.dwIDCommercial;
		this._ynProchaineVisite        = data.ynProchaineVisite;
		this._dtDateProchaineVisite         = data.dtDateProchaineVisite ? DateHelper.fromLocalizedString(data.dtDateProchaineVisite) : null;
		this._dwIDPotentiel        = data.dwIDPotentiel;
		this._ynConsultation        = data.ynConsultation;
		this._ynConsultationPromise        = data.ynConsultationPromise;
		this._strRemarques        = data.strRemarques;
		return this;
	}
}
