import Vue, {VNode} from 'vue';
import goto from 'vuetify/src/services/goto';
import {ObjectString} from 'gollumts-objecttype';
import vueI18N from '@/shared/i18n';
import {FormDirective} from '@/shared/form/form.directive';

export class Form {
	
	public static readonly FIELD_ROOT = 'FIELD_ROOT';

	public static searchParentForm($component: Vue): Form {
		const vnode = this.searchParentVNodeWithForm($component.$vnode);
		return vnode ? this.getFormFromVNode(vnode) : null;
	}

	public static searchParentName($component: Vue): string {
		const vnode = this.searchParentVNodeWithForm($component.$vnode);
		return vnode ? this.getNameFromVNode(vnode) : '';
	}

	public static getFormFromVNode(vnode: VNode): Form {
		return vnode && vnode.data && vnode.data.directives ? vnode.data.directives.filter(d => d.name === 'form').map(d => d.value).shift() : null;
	}

	public static getNameFromVNode(vnode: VNode): string {
		if (vnode) {
			if (vnode.componentInstance && vnode.componentInstance.$attrs && vnode.componentInstance.$attrs['name']) {
				return vnode.componentInstance.$attrs['name'];
			}
			if (vnode.data && vnode.data.attrs && vnode.data.attrs['name']) {
				return vnode.data.attrs['name'];
			}
		}
		return '';
	}
	
	public static searchParentVNodeWithForm(vnode: VNode): VNode {
		if (!vnode) {
			return null;
		}
		if (this.getFormFromVNode(vnode)) {
			return vnode;
		}
		if (vnode.context) {
			return this.searchParentVNodeWithForm(vnode.context.$vnode);
		}
		return null;
	}

	
	public _valid: boolean = true;
	private _formDirectives: ObjectString<FormDirective> = {};
	private _errors: ObjectString<string[]> = {};
	
	public get valid(): boolean {
		return this._valid && !(this.getErrors(Form.FIELD_ROOT)).length
	}
	
	public set valid(value: boolean) {
		this._valid = value;
	}
	
	public addFromDirective(formDirective: FormDirective): this {
		this._formDirectives[formDirective.name] = formDirective;
		return this;
	}
	
	public removeFromDirective(formDirective: FormDirective): this {
		if (this._formDirectives[formDirective.name]) {
			delete this._formDirectives[formDirective.name];
		}
		return this;
	}
	
	public rules(name, rules: ((value: any) => (string|boolean))[] = []): ((value: any) => (string|boolean))[] {
		rules = rules.concat([]);
		if (this.isRequired(name)) {
			rules.push(
				v => this.requireCallback(name, v) || vueI18N.t('error.default.field_required', { label: this.getLabel(name) }).toString(),
			);
		}
		if (this._errors[name]) {
			for (const error  of this._errors[name]) {
				rules.push(v => error);
			}
		}
		return rules;
	}
	
	public async validate(): Promise<boolean> {
		if (this._formDirectives[Form.FIELD_ROOT]) {
			this._formDirectives[Form.FIELD_ROOT].vnode.componentInstance['validate']();
		}
		await Vue.nextTick();
		return this.valid;
	}
	
	private isRequired(name: string): boolean {
		const directive = this._formDirectives[name];
		if (directive) {
			return directive.isRequired;
		}
		return false;
	}
	
	private requireCallback(name: string, v: any): boolean {
		const directive = this._formDirectives[name];
		if (directive) {
			return directive.requireCallback(v);
		}
		return false;
	}
	
	private getLabel(name: string): string {
		const directive = this._formDirectives[name];
		if (directive) {
			return directive.label;
		}
		return '';
	}
	
	public getErrors(name: string): string[] {
		if (this._errors[name]) {
			return this._errors[name];
		}
		return [];
	}
	
	public async addError(name: string, error: string): Promise<void> {
		if (!this._formDirectives[name]) {
			name = Form.FIELD_ROOT;
		}
		if (!this._errors[name]) {
			this._errors[name] = [];
		}
		this._errors[name].push(error);
		await Vue.nextTick();
		this.$forceUpdate();
	}
	
	public clearErrors(search: string|RegExp = null): void {
		if (search) {
			if (search instanceof RegExp) {
				for (const name of Object.keys(this._errors)) {
					if (search.test(name)) {
						delete this._errors[name];
					}
				}
			} else if (this._errors[search]) {
				delete this._errors[search];
			}
		} else {
			this._errors = {};
		}
		this.$forceUpdate();
	}

	public async call(callback: () => Promise<void>, validate: boolean = true, goToError: boolean = true): Promise<void> {
		try {
			this.clearErrors();
			if (!validate || await this.validate()) {
				await callback();
			} else {
				if (goToError) {
					this.goToError();
				}
			}
		} catch (e) {
			this.fromError(e);
			if (goToError) {
				this.goToError();
			}
		}
	}
	
	public goToError(): void {
		const getScrollParent = (node) => {
			if (node == null) {
				return null;
			}
			if (node.scrollHeight > node.clientHeight) {
				return node;
			} else {
				const parent = getScrollParent(node.parentNode);
				return parent ? parent : document.body;
			}
		};


		const root = this._formDirectives[Form.FIELD_ROOT];
		if (root) {
			setTimeout(() => {
				const list = root.$el.find('.form-error, .error--text');
				if (list.length) {
					goto(list[0], {
						container: getScrollParent(root.el),
						offset: 50
					})
				}
			}, 200);
			
			// console.log(, getScrollParent(root.el));
		}
	}
	
	private $forceUpdate(name: string = null): void {
		if (name) {
			const directive = this._formDirectives[name];
			if (directive) {
				directive.$forceUpdate();
			}
		} else {
			for (const name of Object.keys(this._formDirectives)) {
				this.$forceUpdate(name);
			}
		}
	}
		
	public fromError(e: any): void {
		if (e.responseJSON) {
			this.fromJsonError(e.responseJSON);
		} else {
			console.error(e);
			this.addError(Form.FIELD_ROOT, vueI18N.t('error.default.general').toString());
		}
	}

	public fromJsonError(data: any): void {
		if (typeof data === 'string') {
			this.addError(Form.FIELD_ROOT, vueI18N.t(data).toString());
		} else
		if (typeof data == 'object') {

			if (
				typeof data.class   !== 'undefined' &&
				typeof data.code    !== 'undefined' &&
				typeof data.file    !== 'undefined' &&
				typeof data.message !== 'undefined' &&
				typeof data.stack   !== 'undefined'
			) {
				this.addError(name, data.message);
				return;
			}

			for (const name of Object.keys(data)) {
				const message  = data[name];
				if (typeof message == 'string') {
					this.addError(name, message);
				} else
				if (Array.isArray(message)) {
					for (const m of message) {
						this.addError(name, m);
					}
				} else {
					this.addError(Form.FIELD_ROOT, vueI18N.t('error.default.general').toString());
				}
			}
		}
	}
	
}

