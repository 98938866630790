import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorFilterState,
	PaginatorActionWithFilters,
	PaginatorMutation,
	StandardAction, SetterMutation,
} from '@/shared/utils';
import {Commercial, Expedition, User} from '@/models';
import {ExpeditionService} from '@/xhttp';

import {DateFilter} from '@/shared/filter';
import store from '@/stores';

Vue.use(Vuex);

export class ExpeditionFilter {

	name: string = '';
	email: string = '';
	remarques: string = '';
	address: string = '';
	zipCode: string = '';
	town: string = '';
	country: string = '';

	public clear(): void {
		this.name = '';
		this.email = '';
		this.remarques = '';
		this.address = '';
		this.zipCode = '';
		this.town = '';
		this.country = '';
	}

	public toString(): string {
		return JSON.stringify(this);
	}
}

class ExpeditionState extends PaginatorFilterState<ExpeditionFilter> {
	expedition: Expedition = null;
	expeditions: ResultType<Expedition> = null;
	dossier: Array<any> = [];
	public constructor() {
		super(new ExpeditionFilter(), 'expeditions', 'expedition', 'id', AscDesc.DESC, 20)
	}
}

class ExpeditionStore {

	@XHTTPService(() => Expedition)
	private expeditionService: ExpeditionService;

	public state: ExpeditionState = new ExpeditionState();

	public mutations = {
		...PaginatorMutation(() => storeExpedition, 'expeditions', 'expedition'),
		...SetterMutation(['dossier']),
	};

	public actions = {
		...StandardAction<Expedition, ExpeditionState>(() => Expedition, 'expedition'),
		...PaginatorActionWithFilters<Expedition, ExpeditionState>(() => Expedition, 'expeditions', 'expedition'),

		async repertoireExpeditionSearch(context: ActionContext<ExpeditionState, any>, id: number): Promise<Expedition> {
			const expedition = await instance.expeditionService.repertoireExpeditionSearch(id);
			context.commit('setDossier', expedition);
			return expedition;
		}
	};

}

const instance = new ExpeditionStore();
const storeExpedition = new Vuex.Store(instance);
store.registerModule('expedition', storeExpedition);
export default storeExpedition;
