import Vue from 'vue';
import Vuex from 'vuex';
import Time from 'gollumts-time';
import caller from '@/shared/caller';
import store from '@/stores';

Vue.use(Vuex);

class LoaderState {
	loading: boolean = false;
	decounter: number = 0;
	counter: number = 0;
}

class LoaderStore {

	public state: LoaderState = new LoaderState();

	public mutations = {

		disable(state: LoaderState) {
			state.decounter++;
		},

		enable(state: LoaderState) {
			state.decounter--;
		},

		push(state: LoaderState) {
			state.counter++;
			state.loading = (storeLoader.state.counter - storeLoader.state.decounter) > 0;
		},

		pop(state: LoaderState) {
			state.counter--;
			state.loading = (storeLoader.state.counter - storeLoader.state.decounter) > 0;
		},
	};

}

caller.addListener('request', error => {
	storeLoader.commit('push');
});
caller.addListener('complete', error => {
	storeLoader.commit('pop');
});

const instance = new LoaderStore();
const storeLoader = new Vuex.Store(instance);
store.registerModule('loader', storeLoader);
export default storeLoader;
