import {XHTTP} from '@/shared/xhttp';
import {ClientService} from "@/xhttp";
import {Address} from './Address';
import {DateHelper} from "@/shared/utils";
import {Expedition} from "@/models/Expedition";
import {Interlocuteur} from "@/models/Interlocuteur";

@XHTTP(ClientService, '/clients')
export class Client {

	private _cleclient: number;
	private _raisonsoc: string;
	private _adress1: string;
	private _adress2: string;
	private _strNewCodePostal: string;
	private _ville: string;
	private _dwFamCli: number;
	private _strTelSiege: string;
	private _strEMAILSiege: string;
	private _strEMailCompta: string;
	private _dwIDRole: number;
	private _dLatitude: string;
	private _dLongitude: string;
	private _datecre: Date = new Date();
	private _interlocuteurs: Interlocuteur[];

	/////////////
	// Getters //
	/////////////

	public get cleclient(): number {
		return this._cleclient;
	}

	public get raisonsoc(): string {
		return this._raisonsoc;
	}

	public get adress1(): string {
		return this._adress1;
	}

	public get adress2(): string {
		return this._adress2;
	}

	public get strNewCodePostal(): string {
		return this._strNewCodePostal;
	}

	public get ville(): string {
		return this._ville;
	}

	public get dwFamCli(): number {
		return this._dwFamCli;
	}

	public get strTelSiege(): string {
		return this._strTelSiege;
	}

	public get strEMAILSiege(): string {
		return this._strEMAILSiege;
	}

	public get strEMailCompta(): string {
		return this._strEMailCompta;
	}

	public get dwIDRole(): number {
		return this._dwIDRole;
	}

	public get dLatitude(): string {
		return this._dLatitude;
	}

	public get dLongitude(): string {
		return this._dLongitude;
	}

	public get datecre(): Date {
		return this._datecre;
	}

	public get interlocuteurs(): Interlocuteur[] {
		return this._interlocuteurs;
	}

	/////////////
	// Setters //
	/////////////

	public set cleclient(value: number) {
		this._cleclient = value;
	}

	public set raisonsoc(value: string) {
		this._raisonsoc = value;
	}

	public set adress1(value: string) {
		this._adress1 = value;
	}

	public set adress2(value: string) {
		this._adress2 = value;
	}

	public set strNewCodePostal(value: string) {
		this._strNewCodePostal = value;
	}

	public set ville(value: string) {
		this._ville = value;
	}

	public set dwFamCli(value: number) {
		this._dwFamCli = value;
	}

	public set strTelSiege(value: string) {
		this._strTelSiege = value;
	}

	public set strEMAILSiege(value: string) {
		this._strEMAILSiege = value;
	}

	public set strEMailCompta(value: string) {
		this._strEMailCompta = value;
	}

	public set dwIDRole(value: number) {
		this._dwIDRole = value;
	}

	public set dLatitude(value: string) {
		this._dLatitude = value;
	}

	public set dLongitude(value: string) {
		this._dLongitude = value;
	}

	public set datecre(value: Date) {
		this._datecre = value;
	}

	/////////
	// Add //
	/////////


	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			cleclient       : this._cleclient,
			raisonsoc       : this._raisonsoc,
			adress1       : this._adress1,
			adress2       : this._adress2,
			strNewCodePostal       : this._strNewCodePostal,
			ville       : this._ville,
			dwFamCli       : Number(this._dwFamCli),
			strTelSiege       : this._strTelSiege,
			strEMAILSiege       : this._strEMAILSiege,
			strEMailCompta       : this._strEMailCompta,
			dwIDRole       : this._dwIDRole,
			dLatitude       : this._dLatitude,
			dLongitude       : this._dLongitude,
			datecre    : this._datecre ? DateHelper.toLocalizedString(this._datecre) : null,
		};
	}

	public fromJSON(data: any): this {
		this._cleclient       = data.cleclient;
		this._raisonsoc        = data.raisonsoc;
		this._adress1        = data.adress1;
		this._adress2        = data.adress2;
		this._strNewCodePostal        = data.strNewCodePostal;
		this._ville        = data.ville;
		this._dwFamCli        = data.dwFamCli;
		this._strTelSiege        = data.strTelSiege;
		this._strEMAILSiege        = data.strEMAILSiege;
		this._strEMailCompta        = data.strEMailCompta;
		this._dwIDRole        = data.dwIDRole;
		this._dLatitude        = data.dLatitude;
		this._dLongitude        = data.dLongitude;
		this._datecre         = data.datecre ? DateHelper.fromLocalizedString(data.datecre) : null;
		this._interlocuteurs = data.interlocuteurs ? data.interlocuteurs.map(c => (new Interlocuteur()).fromJSON(c)) : null;
		return this;
	}
}
