















import {Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

@Component()
export default class InputPhone extends Vue {
	@InOut({type: String , isVModel: true   }) private value!     : string;
	
	private preventBadChar(): void {
		this.value = this.value ? this.value.replace(/[^0-9 \+]/gm, '') : '';
	}
	
	private format(): void {
		let out = '';
		let value = this.value;
		if (value) {
			console.log(value);
			value = value.replace(/[^0-9]/gm, '');
			if (value[0] === '+') {
				// nothing
			} else 
			if (value[0] === '0') {
				value = value.substr(1);
				value = '+33'+value;
			} else {
				value = '+'+value;
			}
			out += value[0];
			out += value[1] ? value[1]: '';
			out += value[2] ? value[2]: '';
			out += ' ';
			out += value[3] ? value[3]: '';
			out += ' ';
			for (var i = 4; i < value.length; i = i + 2) {
				out += value[i]   ? value[i]  : '';
				out += value[i+1] ? value[i+1]: '';
				out += ' ';
			}
		}
		this.value = out.trim();
	}
}
