import {GenericService} from '@/shared/xhttp';
import {Expedition} from '@/models';

export class ExpeditionService extends GenericService<Expedition> {

	public async repertoireExpeditionSearch(id: number): Promise<Expedition> {
		const result = await this.put(`/repertoire/expedition/search/${id}`);
		console.log(result)
		return result;
	}

}