import Time from 'gollumts-time';

export class FunctionHelper {
	
	public static timeoutAsync(callback: (...args: any[]) => Promise<any>, timeout: number = 10): (...args: any[]) => Promise<any> {
		let lastPromise: Promise<any> = null;
		
		return (...args: any[]): Promise<any> => {
			if (!lastPromise) {
				lastPromise = (async () => {
					await Time.timeout(timeout);
					lastPromise = null;
					return callback.apply(null, args);
				})();
			}
			return lastPromise;
		};
	}
	
}