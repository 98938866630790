import {XHTTP} from '@/shared/xhttp';
import {TokenService} from '@/xhttp';
import {User} from './User';

export class TokenLogin {
	email: string = '';
	password: string = '';
}

@XHTTP(TokenService, '/tokens')
export class Token {
	
	private _id: string;
	private _lifetime: number = null;
	private _rights: string[] = [];
	private _name: string = null;
	private _user: User;
	private _createTime: number;
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): string {
		return this._id;
	}
	
	public get lifetime(): number {
		return this._lifetime;
	}
	
	public get rights(): string[] {
		return this._rights;
	}
	
	public get name(): string {
		return this._name;
	}
	
	public get user(): User {
		return this._user;
	}
	
	public get createTime(): number {
		return this._createTime;
	}

	/////////////
	// Setters //
	/////////////
	
	public set lifetime(value: number) {
		this._lifetime = value;
	}
	
	public set rights(value: string[]) {
		this._rights = value;
	}
	
	public set name(value: string) {
		this._name = value;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id      : this.id,
			lifetime: this.lifetime,
			rights  : this.rights,
			name    : this.name,
		};
	}

	public fromJSON(data: any): this {
		this._id         = data.id;   
		this._lifetime   = data.lifetime;         
		this._rights     = data.rights;       
		this._name       = data.name;     
		this._user       = data.user ? (new User()).fromJSON(data.user) : null;     
		this._createTime = data.createTime;         
		return this;
	}
}