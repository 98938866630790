import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorFilterState,
	PaginatorActionWithFilters,
	PaginatorMutation,
	StandardAction, SetterMutation,
} from '@/shared/utils';
import { Client } from '@/models';
import {ClientService} from "@/xhttp";

import {DateFilter} from '@/shared/filter';
import store from '@/stores';

Vue.use(Vuex);

export class ClientFilter {

	date: string = '';
	boisType: string = '';
	longueursClient: string = '';
	volume: string = '';
	prixStere: string = '';
	transporteur: string = '';
	facture: string = '';
	address: string = '';

	public clear(): void {
		this.date = '';
		this.boisType = '';
		this.longueursClient = '';
		this.volume = '';
		this.prixStere = '';
		this.transporteur = '';
		this.facture = '';
		this.address = '';
	}

	public toString(): string {
		return JSON.stringify({
			address	: this.address,
		});

	}
}

class ClientState extends PaginatorFilterState<ClientFilter> {
	client: Client = null;
	clients: ResultType<Client> = null;
	public constructor() {
		super(new ClientFilter(), 'clients', 'client', 'date', AscDesc.DESC, 20)
	}
}

class ClientStore {

	@XHTTPService(() => Client)
	private clientService: ClientService;

	public state: ClientState = new ClientState();

	public mutations = {
		...PaginatorMutation(() => storeClient, 'clients', 'client'),
	};

	public actions = {
		...StandardAction<Client, ClientState>(() => Client, 'client'),
		...PaginatorActionWithFilters<Client, ClientState>(() => Client, 'clients', 'client'),
	};

}

const instance = new ClientStore();
const storeClient = new Vuex.Store(instance);
store.registerModule('client', storeClient);
export default storeClient;
