












import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';

import storeNotifier, {NotifyInterface} from '@/stores/modules/notifier';

@Component()
export default	 class Notify extends Vue {
	
	@Stored(() => storeNotifier)
	private notifies: NotifyInterface[];
	
}
