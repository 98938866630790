import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorFilterState,
	PaginatorActionWithFilters,
	PaginatorMutation,
	StandardAction, SetterMutation,
} from '@/shared/utils';
import { Prospection } from '@/models';
import {ProspectionService} from "@/xhttp";

import {DateFilter} from '@/shared/filter';
import store from '@/stores';

Vue.use(Vuex);

export class ProspectionFilter {

	date: string = '';
	boisType: string = '';
	longueursProspection: string = '';
	volume: string = '';
	prixStere: string = '';
	transporteur: string = '';
	facture: string = '';
	address: string = '';

	public clear(): void {
		this.date = '';
		this.boisType = '';
		this.longueursProspection = '';
		this.volume = '';
		this.prixStere = '';
		this.transporteur = '';
		this.facture = '';
		this.address = '';
	}

	public toString(): string {
		return JSON.stringify({
			address	: this.address,
		});

	}
}

class ProspectionState extends PaginatorFilterState<ProspectionFilter> {
	prospection: Prospection = null;
	prospections: ResultType<Prospection> = null;
	public constructor() {
		super(new ProspectionFilter(), 'prospections', 'prospection', 'date', AscDesc.DESC, 20)
	}
}

class ProspectionStore {

	@XHTTPService(() => Prospection)
	private prospectionService: ProspectionService;

	public state: ProspectionState = new ProspectionState();

	public mutations = {
		...PaginatorMutation(() => storeProspection, 'prospections', 'prospection'),
	};

	public actions = {
		...StandardAction<Prospection, ProspectionState>(() => Prospection, 'prospection'),
		...PaginatorActionWithFilters<Prospection, ProspectionState>(() => Prospection, 'prospections', 'prospection'),
	};

}

const instance = new ProspectionStore();
const storeProspection = new Vuex.Store(instance);
store.registerModule('prospection', storeProspection);
export default storeProspection;
