







































import {Emit} from 'vue-property-decorator';
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Commercial} from '@/models';

import CrudCommercialEdit from '@/components/Crud/Commercial/Edit.vue';

@Component({
	components: {
		CrudCommercialEdit,
	}
})
export default class CrudCommercialEditDialog extends Vue {

	@InOut({ type: Commercial  , isVModel: true }) private value!: Commercial;

	private number: number = null;

	@Emit()
	private onSubmit(input_number) {
		this.number = input_number;
		this.onClose();
	}

	@Emit()
	private onClose() {
		if (this.number)
		{
			this.$router.push({name: 'commercial-repertoire', params: { id : this.number.toString() }});
		}
		this.value = null;
	}
}
