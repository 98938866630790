import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import Time from 'gollumts-time';
import store from '@/stores';

Vue.use(Vuex);

export type NotifyType = 'error'|'warning'|'success';

export interface NotifyInterface {
	type?: NotifyType;
	message?: string;
	timeout?: number;
}

interface NotifierState {
	notifies: NotifyInterface[];
}

/**
 * Store in indexDb an user
 */
class NotifierStore {

	public state: NotifierState = {
		notifies: [],
	};

	public mutations = {
		addNotify(state: NotifierState, notify: NotifyInterface) {
			state.notifies.push(notify);
		},

		removeNotify(state: NotifierState, notify: NotifyInterface) {
			const index = state.notifies.indexOf(notify);
			state.notifies.splice(index, 1);
		},
	};

	public actions = {

		async notify(context: ActionContext<NotifierState, any>, notify: NotifyInterface): Promise<NotifyInterface> {
			notify = {
				...{
					type:  'success',
					message: '',
					timeout: 5000
				},
				...notify,
			};
			context.commit('addNotify', notify);
			await Time.timeout(notify.timeout);
			context.commit('removeNotify', notify);
			return notify;
		},

	};

}
const instance = new NotifierStore();
const storeNotifier = new Vuex.Store(instance);
store.registerModule('notifier', storeNotifier);
export default storeNotifier;
