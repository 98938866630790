import Vue from 'vue';
import VueRouter from 'vue-router';
import granted from '@/shared/granted';
import vuetify from '@/shared/vuetify';

import storeNotifier, {NotifyInterface} from '@/stores/modules/notifier';

import Login from '@/views/Login.vue';
import ResetPasswordConfirm from '@/views/ResetPasswordConfirm.vue';

import user from './user';
import expedition from './commercial';
import commercial from './expedition'
import commande from './commande'
import devis from "./devis";
import planningFabrication from './planningFabrication'
import planningBE from './planningBE'
import token from './token';
import client from './client';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'login',
		meta: { loginPage: true },
		component: Login,
	},
	{
		path: '/reset-password-confirm/:email',
		name: 'reset_password_confirm',
		meta: { loginPage: true },
		component: ResetPasswordConfirm,
	},
	{
		path: '/tableau',
		name: 'tableau',
		meta: { granted: [] },
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home.vue')
	},
	{
		path: '/',
		name: 'home',
		meta: { granted: [] },
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home.vue')
	},
	{
		path: '/profile',
		name: 'profile',
		meta: { granted: [] },
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Profile.vue')
	},
	...user,
	...commercial,
	...commande,
	...devis,
	...planningFabrication,
	...planningBE,
	...expedition,
	...token,
	...client,
	{
		path: '*',
		redirect: { name: 'home' },
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
});

router.beforeEach((to: any, from: any, next: Function) => {
	if (granted.firstPageLoaded() && !granted.checkRouteGranted(to)) {
		storeNotifier.dispatch('notify', {
			type: 'error',
			message: 'Accès refusé'
		} as NotifyInterface);
		if (granted.isConnected()) {
			next({ name: 'home'});
			return;
		}
		next({ name: 'login'});
		return;
	}
	next();
});
export default router
