import jQuery from 'jquery';
import {VNode} from 'vue';
import {Vue} from 'vue-property-decorator';
import {DirectiveBinding} from 'vue/types/options';
import {ObjectConstructor, ObjectString} from 'gollumts-objecttype';
import {Form} from './Form';
import FormError from './FormError.vue';

export abstract class FormDirective {

	private _vnode: VNode;
	private _form: Form;

	public static get TAGS(): ObjectString<ObjectConstructor<FormDirective>> {
		return {
			'v-form'        : FormDirectiveForm,
			'v-switch'      : FormDirectiveInput,
			'v-text-field'  : FormDirectiveTextField,
			'v-textarea'    : FormDirectiveTextField,
			'v-date-picker' : FormDirectiveTextField,
			'v-autocomplete': FormDirectiveTextField,
			'v-select'      : FormDirectiveSelect,
		};
	}

	public abstract readonly name: string;
	public abstract readonly label: string;
	public abstract readonly isRequired: boolean;

	public get vnode(): VNode {
		return this._vnode;
	}

	public get form(): Form {
		return this._form;
	}

	public get el(): HTMLElement {
		return <HTMLElement>this._vnode.elm;
	}

	public get $el(): JQuery {
		return jQuery(this.el);
	}

	public requireCallback(v: any): boolean {
		return !!v || v === 0;
	}

	public constructor(binding: DirectiveBinding, vnode: VNode) {
		this._vnode = vnode;
		this._form = binding.value;
	}

	public register() {
		this._form.addFromDirective(this);
		this._vnode.componentInstance.$nextTick().then(() => {
			this._vnode.componentInstance.$forceUpdate();
			this._vnode.componentInstance.$parent.$forceUpdate();
		})
	}

	public update(binding: DirectiveBinding, vnode: VNode) {
		this._vnode = vnode;
		this._form = binding.value;
	}

	public unbind() {
		this._form.removeFromDirective(this);
	}

	public $forceUpdate(): void {
		this.vnode.componentInstance.$forceUpdate();
	}
}

export class FormDirectiveInput extends FormDirective {

	private _name: string;
	private _label: string;

	public get name(): string {
		if (!this._name) {
			if (this.vnode.data.attrs.name) {
				this._name = this.vnode.data.attrs.name;
			}
			if (this.vnode.componentInstance &&   this.vnode.componentInstance.$attrs.name) {
				this._name = this.vnode.componentInstance.$attrs.name;
			}
		}
		return this._name;
	}

	public get label(): string {
		if (this.vnode.data.attrs.label) {
			this._label = this.vnode.data.attrs.label;
		} else
		if (this.vnode.componentInstance && this.vnode.componentInstance['label']) {
			this._label = this.vnode.componentInstance['label'];
		} else
		if (this.vnode.componentInstance && this.vnode.componentInstance.$attrs.label) {
			this._label = this.vnode.componentInstance.$attrs.label;
		}
		return this._label;
	}

	public get isRequired(): boolean {
		return (
			this.vnode.data.attrs &&
			typeof this.vnode.data.attrs.required !== 'undefined' &&
			this.vnode.data.attrs.required !== false
		) || (
			this.vnode.data.attrs &&
			typeof this.vnode.data.attrs.required !== 'undefined' &&
			this.vnode.data.attrs.required !== false
		);
	}

}

export class FormDirectiveTextField extends FormDirectiveInput {
	private _eventChange: Function;

	public register() {
		super.register();
		this._eventChange = () => this.form.clearErrors(this.name);
		this.vnode.componentInstance.$on('keydown', this._eventChange);
	}

	public unbind() {
		super.unbind();
		this.vnode.componentInstance.$off('keydown', this._eventChange);
	}
}

export class FormDirectiveSelect extends FormDirectiveInput {

	public requireCallback(v: any): boolean {
		if (this.vnode.componentInstance['multiple']) {
			return v && v.length;
		}
		return !!v;
	}
}

export class FormDirectiveForm extends FormDirectiveInput {

	private _formErrorComponent: Vue;

	public get name(): string {
		return Form.FIELD_ROOT;
	}

	public get label(): string {
		return '';
	}

	public get isRequired(): boolean {
		return false;
	}

	public register() {
		super.register();

		const $elError = jQuery('<span></span>').prependTo(<any>this.vnode.elm);
		this._formErrorComponent = new FormError({
			propsData: {
				form: this.form,
			}
		}).$mount($elError[0]);
		(<any>this._formErrorComponent).$parent = this.vnode.componentInstance;
		this.vnode.componentInstance.$children.push(this._formErrorComponent);
	}

	public $forceUpdate(): void {
		super.$forceUpdate();
		this.vnode.context.$forceUpdate();
		this._formErrorComponent.$forceUpdate();
	}

	public unbind() {
		super.unbind();
		const index = this.vnode.componentInstance.$children.indexOf(this._formErrorComponent);
		if (index !== -1) {
			jQuery(this._formErrorComponent.$el).remove();
			this.vnode.componentInstance.$children.splice(index, 1);
		}
	}
}

const createDirective = (el: HTMLElement, binding: DirectiveBinding, vnode: VNode): FormDirective => {
	if (!binding.value) {
		return;
	}

	const findDirectiveClass = (vnode: VNode): ObjectConstructor<FormDirective> => {
		if (!vnode.componentOptions || !vnode.componentInstance) {
			return null;
		}
		let directiveClass = FormDirective.TAGS[vnode.componentOptions.tag];
		if (!directiveClass) {
			for (const child of vnode.componentInstance.$children) {
				directiveClass = findDirectiveClass(child.$vnode);
				if (directiveClass) {
					break
				}
			}
		}
		return directiveClass;
	};
	const directiveClass = findDirectiveClass(vnode);

	if (directiveClass) {
		return new directiveClass(binding, vnode);
	}
	console.warn('Form directive no found for tag:', vnode.componentOptions ? vnode.componentOptions.tag : null, 'use FormDirectiveTextField by default')
	return new FormDirectiveTextField(binding, vnode);
};

const deleteDirective = (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
	if (el['__formDirectiveAction__']) {
		el['__formDirectiveAction__'].unbind();
		delete el['__formDirectiveAction__'];
	}
};

const updateDirective = (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
	if (!binding.value) {
		deleteDirective(el, binding, vnode);
		return;
	}

	let directive: FormDirective = el['__formDirectiveAction__'];
	const newDirective = createDirective(el, binding, vnode);
	if (
		directive &&
		directive.el !== directive.el &&
		directive.vnode !== directive.vnode &&
		directive.name !== newDirective.name
	) {
		deleteDirective(el, binding, vnode);
	}
	directive = el['__formDirectiveAction__'];
	if (!directive) {
		el['__formDirectiveAction__'] = newDirective;
		newDirective.register();
	}
};

export default {

	bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		updateDirective(el, binding, vnode);
	},

	unbind(el: HTMLElement, binding: any, vnode: VNode) {
		deleteDirective(el, binding, vnode);
	},

	updated: (el: HTMLElement, binding: any, vnode: VNode) => {
		updateDirective(el, binding, vnode);
	},

	componentUpdated(el: HTMLElement, binding: any, vnode: VNode) {
		updateDirective(el, binding, vnode);
	}

}
