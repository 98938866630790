










import {Component, Vue} from '@/shared/component';
import {Stored} from 'vue-stored-prop-decorator';
import storeLoader from '@/stores/modules/loader';

@Component()
export default class AjaxLoader extends Vue {
	@Stored(() => storeLoader) private loading!: boolean;
}
