import {XHTTP} from '@/shared/xhttp';
import {RedirectService} from '@/xhttp';

@XHTTP(RedirectService, '/redirects')
export class Redirect {
	
	private _href: string;
	
	/////////////
	// Getters //
	/////////////

	public get href(): string {
		return this._href;
	}

	/////////////
	// Setters //
	/////////////

	public set href(value: string) {
		this._href = value;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			href: this._href,
		};
	}

	public fromJSON(data: any): this {
		this._href = data.href;   
		return this;
	}
}