import {DateHelper} from '@/shared/utils';

export type DateFilterMode = 'equal'|'range'; 
export const DateFilterMode = {
	EQUAL: 'equal' as DateFilterMode,
	RANGE: 'range' as DateFilterMode,
};

export class DateFilter {
	public mode: DateFilterMode = DateFilterMode.EQUAL;
	public equal: Date = null;
	public start: Date = null;
	public end: Date = null;
	
	public toJSON(): any {
		return {
			mode:  this.mode,
			equal: this.equal ? DateHelper.toLocalizedString(this.equal) : null,
			start: this.start ? DateHelper.toLocalizedString(this.start) : null,
			end:   this.end   ? DateHelper.toLocalizedString(this.end)   : null,
		};
	}
}