import jQuery from 'jquery';
import {VNode} from 'vue';
import {DirectiveBinding} from 'vue/types/options';
import {ObjectString} from 'gollumts-objecttype';

class ToggleShowDirective {
	
	public static styleInjected: boolean = false;
	public static _targets: ObjectString<VNode[]> = {};
	public static _targetsDirective: ObjectString<ToggleShowDirective> = {};
	
	private _vnode: VNode;
	private _event: any;
	private _target: any;
	private isOpen: boolean = false;
	
	public static addTarget(target: string, vnode: VNode) {
		if (!this._targets[target]) {
			this._targets[target] = [];
		}
		this._targets[target].push(vnode);
		
		if (this._targetsDirective[target]) {
			if(this._targetsDirective[target].isOpen) {
				this._targetsDirective[target].open();
			} else {
				this._targetsDirective[target].close();
			}
		}
	}
	
	public static removeTarget(target: string, vnode: VNode) {
		if (this._targets[target]) {
			const index = this._targets[target].indexOf(vnode);
			if (index !== -1) {
				this._targets[target] = this._targets[target].splice(index, 1);
			}
			jQuery(vnode.elm).show(300);
		}
	}
	
	constructor(binding:any, vnode: VNode) {
		
		if (!ToggleShowDirective.styleInjected) {
			ToggleShowDirective.styleInjected = true;
			jQuery(`<style type="text/css">
				.toggle-show-applied:not(.toggle-show-open) {
					display: none !important;
				}
				.toggle-show-btn.toggle-show-arrow,
				.toggle-show-btn .toggle-show-arrow {
					cursor: pointer;
				}
				.toggle-show-btn.toggle-show-arrow:after,
				.toggle-show-btn .toggle-show-arrow:after {
					content: '▶';
					transition: transform 0.3s;
					display: inline-block;
					color: #777;
					font-size: 13px;
					margin-left: 10px;
					vertical-align: 1px;
				}
				.toggle-show-btn.toggle-show-arrow.toggle-show-arrow-open:after,
				.toggle-show-btn .toggle-show-arrow.toggle-show-arrow-open:after {
					transform: rotate(90deg);
				}
				.toggle-show-btn.toggle-show-arrow.toggle-show-arrow-open.toggle-arrow-reverse:after,
				.toggle-show-btn .toggle-show-arrow.toggle-show-arrow-open.toggle-arrow-reverse:after {
					transform: rotate(-90deg);
				}
			</style>`).appendTo(document.head);
		}
		
		this._target = binding.value;
		this._vnode = vnode;
		this._event = () => {
			this.toggle();
		};
		const $el = jQuery(this._vnode.elm);
		$el.on('click', this._event);
		$el.addClass('toggle-show-btn');
		
		ToggleShowDirective._targetsDirective[this._target] = this;
		
		if (this.isOpen) {
			this.open();
		} else {
			this.close();
		}
	}
	
	public unbind(): void {
		this.open();
		const $el = jQuery(this._vnode.elm);
		$el.off('click', this._event);
		$el.removeClass('toggle-show-btn');
	}
	
	private toggle(): void {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}
	
	public open(): void {
		this.isOpen = true;
		if (ToggleShowDirective._targets[this._target]) {
			for (const vnodeTarget of ToggleShowDirective._targets[this._target]) {
				jQuery(vnodeTarget.elm).show(300);
			}
		}
		jQuery(this._vnode.elm).addClass('toggle-show-arrow-open');
		
		const targetSync = this._vnode.elm['__ToggleShowDirective_sync__'];
		if (targetSync && this._vnode.context[targetSync] !== true) {
			this._vnode.context[targetSync] = true;
		}
	}
	
	public close(): void {
		this.isOpen = false;
		if (ToggleShowDirective._targets[this._target]) {
			for (const vnodeTarget of ToggleShowDirective._targets[this._target]) {
				jQuery(vnodeTarget.elm).hide(300);
			}
		}
		jQuery(this._vnode.elm).removeClass('toggle-show-arrow-open');
		
		const targetSync = this._vnode.elm['__ToggleShowDirective_sync__'];
		if (targetSync && this._vnode.context[targetSync] !== false) {
			this._vnode.context[targetSync] = false;
		}
	}
}

const unbindButton = (el: HTMLElement, binding: any, vnode: VNode) => {
	const directive: ToggleShowDirective = el['__ToggleShowDirective__'];
	if (directive) {
		directive.unbind();
	}
};

export const toggleButton = {
	
	bind(el: HTMLElement, binding: any, vnode: VNode) {
		if (binding.value === false) {
			unbindButton(el, binding, vnode);
			return;
		}
		
		const directive = new ToggleShowDirective(binding, vnode);
		
		el['__ToggleShowDirective__'] = directive;
		
		if (typeof el['__ToggleShowDirective_open__'] === 'undefined') {
			el['__ToggleShowDirective_open__'] = false;
		}
		
		if (el['__ToggleShowDirective_open__']) {
			if (el['__ToggleShowDirective_open__']) {
				directive.open();
			} else {
				directive.close();
			}
		}
	},
	
	unbind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		unbindButton(el, binding, vnode);
	},
};

export const toggleTarget = {
	
	bind(el: HTMLElement, binding: any, vnode: VNode) {
		ToggleShowDirective.addTarget(binding.value, vnode);
	},
	
	unbind(el: HTMLElement, binding: any, vnode: VNode) {
		ToggleShowDirective.removeTarget(binding.value, vnode);
	},
};

const applyOpen = (el: HTMLElement, binding: any, vnode: VNode) => {
	const directive: ToggleShowDirective = el['__ToggleShowDirective__'];
	if (directive) {
		if (binding.value) {
			directive.open()
		} else {
			directive.close()
		}
	}
	el['__ToggleShowDirective_open__'] = binding.value;
};

export const toggleOpen = {
	bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		
		applyOpen(el, binding, vnode);
		if (binding.modifiers.sync) {
			el['__ToggleShowDirective_sync__'] = binding.expression;
		}
	},
	
	componentUpdated(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		if (binding.modifiers.sync) {
			applyOpen(el, binding, vnode);
		}
	},
	
	update(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		if (binding.modifiers.sync) {
			applyOpen(el, binding, vnode);
		}
	},
};

export const toggleArrow = {
	bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		if (binding.value || typeof binding.value === 'undefined') {
			el.classList.add('toggle-show-arrow');
		} else {
			el.classList.remove('toggle-show-arrow');
		}
	},
	
	componentUpdated(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		if (binding.value || typeof binding.value === 'undefined') {
			el.classList.add('toggle-show-arrow');
		} else {
			el.classList.remove('toggle-show-arrow');
		}
	},
	
	update(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		if (binding.value || typeof binding.value === 'undefined') {
			el.classList.add('toggle-show-arrow');
		} else {
			el.classList.remove('toggle-show-arrow');
		}
	},
};