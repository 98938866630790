




































	import {Emit} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import {InOut} from "vue-inout-prop-decorator/index";
	import {Stored} from 'vue-stored-prop-decorator';
	import {Form} from '@/shared/form';
	import {TokenLogin} from '@/models';

	import storeUser from '@/stores/modules/user';
	import storeLoader from '@/stores/modules/loader';
	import storeNotifier, {NotifyInterface} from '@/stores/modules/notifier';
	
	@Component()
	export default class ResetPasswordRequestForm extends Vue {

		@Stored(() => storeLoader)
		private loading!: boolean;

		@InOut({ type: String, isVModel:true })
		private value: string;
		
		private form: Form = new Form();

		private showPassword: boolean = false;

		@Emit()
		public displayLogin(): void {
        }
		
		public async resetPassword(): Promise<void> {
            this.form.call(async () => {
                await storeUser.dispatch('patchResetPasswordRequest', this.value);
                this.displayLogin();

                storeNotifier.dispatch('notify', {
                    type: 'success',
                    message: 'Email de changement de mot de passe envoyer'
                } as NotifyInterface);
            });
		}
	}

