import {Commercial} from '@/models';

export default [
	{
		path: '/commercial',
		name: 'commercials',
		meta: {
			title: 'Liste des commercials',
			granted: [ 'API_GET_COMMERCIAL' ],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Commercial.vue')
	},
	{
		path: '/prospection',
		name: 'prospections',
		meta: {
			title: 'Liste des prospections',
			granted: [ 'API_GET_COMMERCIAL' ],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Prospection.vue')
	},
	{
		path: '/commercial/repertoire/:id',
		name: 'commercial-repertoire',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Commercial/ShowRepertoire.vue'),
				class: Commercial
			}),
		},
		meta: {
			title: 'Détail d\'un commercial',
			granted: [ 'API_GET_COMMERCIAL' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/commercial/edit/:id',
		name: 'commercial-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Commercial/Edit.vue'),
				class: Commercial
			}),
		},
		meta: {
			title: 'Edition d\'un commercial',
			granted: [ 'API_PUT_COMMERCIAL' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/commercial/map',
		name: 'commercials-map',
		meta: {
			title: 'Carte des clients',
			granted: [ 'API_GET_COMMERCIAL' ],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Map.vue')
	},
	{
		path: '/gallery',
		name: 'gallery',
		meta: {
			title:'Photothèque',
			granted: [ 'API_GET_COMMERCIAL' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Gallery.vue')
	},
];
