
































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Expedition} from '@/models';

import storeExpedition from '@/stores/modules/expedition';

import InputDatePicker from "@/components/Input/DatePicker.vue";

@Component({
	components: {
		InputDatePicker,
	}
})
export default class CrudExpeditionEdit extends AbstractEditVue<Expedition> {

	// DF: utiliser pour la création d'un expedition uniquement
	// en modification on passe par la light
	private number: number = null;

	public constructor() {
		super(
			storeExpedition,
			'Expedition modifié',
			'Expedition créé',
		);
	}

//	@Emit()
//	private onNext() {
//		if ((!this.item.address) || (this.item.address === null) )
//		{
//			this.item.address = this.item.name;
//		}
//		this.step = 2;
//	}

	protected async onSubmit(): Promise<void> {
		this.$emit('on-submit', this.number);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
	}

	public async refresh(): Promise<void> {
		await super.refresh();
		//console.log('route expedition edit = ' + this.$route.name);
	}

}
