export default [
	{
		path: '/planning/fabrication',
		name: 'planning_fabrication',
		meta: {
			title: 'Planning fabrications',
			granted: [ 'API_GET_PLANNING_FABRICATION' ],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/PlanningFabrication.vue')
	},
];
