import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

class MainStoreState {
}

class MainStore {
  public strict: false;
  public state: MainStoreState = new MainStoreState();
}

const instance = new MainStore();
const store = new Vuex.Store(instance);
export default store;
