import {XHTTP} from '@/shared/xhttp';
import {CountryService} from '@/xhttp';

@XHTTP(CountryService, '/countries')
export class Country {
	
	private _id: number;
	private _name: string;
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): number {
		return this._id;
	}
	
	public get name(): string {
		return this._name;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id: this._id,
		};
	}

	public fromJSON(data: any): this {
		this._id   = data.id;
		this._name = data.name;
		return this;
	}
}