import {ActionContext} from 'vuex';
import {ObjectConstructor} from 'gollumts-objecttype';
import xhttp from '@/shared/xhttp';

export const StandardAction = <E, S>(
	type: () => ObjectConstructor<E>,
	currentName: string = null,
	actionList: string[] = [ 'get', 'post', 'put', 'delete' ]
): any => {
	const actions = {};
	const commitMethod = currentName ? 'set'+currentName[0].toUpperCase()+currentName.substr(1) : null;
	
	if (actionList.indexOf('get') !== -1) {
		actions['get'] = async (context: ActionContext<S, any>, id: number): Promise<E> => {
			const current = await xhttp.get(type()).get('/'+id);
			if (commitMethod) {
				context.commit(commitMethod, current);
			}
			return current;
		}
	}

	if (actionList.indexOf('post') !== -1) {
		actions['post'] = async (context: ActionContext<S, any>, entity: E): Promise<E> => {
			const current = await xhttp.get(type()).post('', entity);
			if (commitMethod) {
				context.commit(commitMethod, current);
			}
			return current;
		}
	}

	if (actionList.indexOf('put') !== -1) {
		actions['put'] = async (context: ActionContext<S, any>, entity: E): Promise<E> => {
			const current = await xhttp.get(type()).put('/{id}', entity);
			if (commitMethod) {
				context.commit(commitMethod, current);
			}
			return current;
		}
	}

	if (actionList.indexOf('delete') !== -1) {
		actions['delete'] = async (context: ActionContext<S, any>, entity: E): Promise<E> => {
			const list = context.state['list'];
			if (list) {
				const index = list.data.indexOf(entity);
				if (index !== -1) {
					list.data.splice(index, 1);
				}
			}
			try {
				const current = await xhttp.get(type()).delete('/{id}', entity);
				if (commitMethod) {
					context.commit(commitMethod, current);
				}
				if (list) {
					context.dispatch('getC', { filters: true });
				}
				return current;
			} catch (e) {
				if (list) {
					context.dispatch('getC', { filters: true });
				}
				throw e;
			}
		}
	}
	
	return actions;
};
