import {ObjectString} from 'gollumts-objecttype';

export class Media {

    private _id: number;
    private _fileName: string;
    private _size: number;
    private _type: string;
    private _public: boolean;
    private _hrefs: ObjectString<string>;

    /////////////
    // Getters //
    /////////////

    public  get id(): number {
        return this._id;
    }

    public get fileName(): string {
        return this._fileName;
    }

    public get size(): number {
        return this._size;
    }

    public get type(): string {
        return this._type;
    }

    public get public(): boolean {
        return this._public;
    }

    public get hrefs(): ObjectString<string> {
        return this._hrefs;
    }

    /////////////
    // Setters //
    /////////////

    public set fileName(value: string) {
        this._fileName = value;
    }

    ///////////////
    // Serialize //
    ///////////////

    public toJSON(): any {
        return {
            fileName: this._fileName,
        };
    }

    public fromJSON(data: any): this {
        this._id       = data.id;
        this._fileName = data.fileName;
        this._size     = data.size;
        this._type     = data.type;
        this._public   = !!data.public;
        this._hrefs    = data.hrefs;
        return this;
    }
}
