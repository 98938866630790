import {XHTTP} from '@/shared/xhttp';
import {ClientService} from "@/xhttp";
import {Address} from './Address';
import {DateHelper} from "@/shared/utils";
import {Expedition} from "@/models/Expedition";
import {FonctionInterlocuteurService} from "@/xhttp/FonctionInterlocuteurService";
import {Interlocuteur} from "@/models/Interlocuteur";

@XHTTP(FonctionInterlocuteurService, '/fonction-interlocuteurs')
export class FonctionInterlocuteur {

	private _dwIDFonction: number;
	private _strNomFonction: string;

	/////////////
	// Getters //
	/////////////

	public get dwIDFonction(): number {
		return this._dwIDFonction;
	}

	public get strNomFonction(): string {
		return this._strNomFonction;
	}

	/////////////
	// Setters //
	/////////////

	public set dwIDFonction(value: number) {
		this._dwIDFonction = value;
	}

	public set strNomFonction(value: string) {
		this._strNomFonction = value;
	}

	/////////
	// Add //
	/////////


	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			dwIDFonction       : this._dwIDFonction,
			strNomFonction       : this._strNomFonction,
		};
	}

	public fromJSON(data: any): this {
		this._dwIDFonction       = data.dwIDFonction;
		this._strNomFonction        = data.strNomFonction;
		return this;
	}
}
