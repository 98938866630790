import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorFilterState,
	PaginatorActionWithFilters,
	PaginatorMutation,
	StandardAction, SetterMutation,
} from '@/shared/utils';
import { FamCli } from '@/models';
import {FamCliService} from "@/xhttp";

import {DateFilter} from '@/shared/filter';
import store from '@/stores';

Vue.use(Vuex);

export class FamCliFilter {

	dwID: number = null;
	strTypeClient: string = '';

	public clear(): void {
		this.dwID = null;
		this.strTypeClient = '';
	}

	public toString(): string {
		return JSON.stringify({
		});
	}
}

class FamCliState extends PaginatorFilterState<FamCliFilter> {
	famcli: FamCli = null;
	famclis: ResultType<FamCli> = null;
	public constructor() {
		super(new FamCliFilter(), 'famclis', 'famcli', 'date', AscDesc.DESC, 20)
	}
}

class FamCliStore {

	@XHTTPService(() => FamCli)
	private famcliService: FamCliService;

	public state: FamCliState = new FamCliState();

	public mutations = {
		...PaginatorMutation(() => storeFamCli, 'famclis', 'famcli'),
	};

	public actions = {
		...StandardAction<FamCli, FamCliState>(() => FamCli, 'famcli'),
		...PaginatorActionWithFilters<FamCli, FamCliState>(() => FamCli, 'famclis', 'famcli'),
	};

}

const instance = new FamCliStore();
const storeFamCli = new Vuex.Store(instance);
store.registerModule('famcli', storeFamCli);
export default storeFamCli;
