import Vue from 'vue';
import { LMap, LMarker, LTileLayer, LCircle } from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-marker', LMarker);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-circle', LCircle);

delete L.Icon.Default.prototype['_getIconUrl'];
L.Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});