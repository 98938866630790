import {XHTTP} from '@/shared/xhttp';
import {ClientService} from "@/xhttp";
import {Address} from './Address';
import {DateHelper} from "@/shared/utils";
import {Expedition} from "@/models/Expedition";
import {FamCliService} from "@/xhttp/FamCliService";

@XHTTP(FamCliService, '/famclis')
export class FamCli {

	private _dwID: number;
	private _strTypeClient: string;

	/////////////
	// Getters //
	/////////////

	public get dwID(): number {
		return this._dwID;
	}

	public get strTypeClient(): string {
		return this._strTypeClient;
	}

	/////////////
	// Setters //
	/////////////

	public set dwID(value: number) {
		this._dwID = value;
	}

	public set strTypeClient(value: string) {
		this._strTypeClient = value;
	}

	/////////
	// Add //
	/////////


	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			dwID       : this._dwID,
			strTypeClient       : this._strTypeClient,
		};
	}

	public fromJSON(data: any): this {
		this._dwID       = data.dwID;
		this._strTypeClient        = data.strTypeClient;
		return this;
	}
}
