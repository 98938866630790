







































import {Emit} from 'vue-property-decorator';
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Client} from '@/models';

import CrudClientEdit from '@/components/Crud/Client/Edit.vue';

@Component({
	components: {
		CrudClientEdit,
	}
})
export default class CrudClientEditDialog extends Vue {

	@InOut({ type: Client  , isVModel: true }) private value!: Client;

	private number: number = null;

	@Emit()
	private onSubmit(input_number) {
		this.number = input_number;
		this.onClose();
	}

	@Emit()
	private onClose() {
		if (this.number)
		{
			this.$router.push({name: 'client-stats', params: { id : this.number.toString() }});
		}
		this.value = null;
	}
}
