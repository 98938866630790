import {Reader} from 'gollumts-annotation';
import {ObjectString} from 'gollumts-objecttype';
import {Uuid} from '@/shared/utils';
import {XHTTP, XHTTPAnnotation} from './Annotation';
import {IService} from './Services';

export class XHTTPManager {
	
	protected _services: ObjectString<IService<any>> = {};

	protected createService(entityClass): void {
		const anno: XHTTPAnnotation = Reader.findClassAnnotation(entityClass, XHTTP);
		this._services[entityClass['__xhttp_uuid__']] = new anno.service(entityClass, anno.url);
	}
	
	public get(entityClass: any): IService<any> {
		if (!entityClass['__xhttp_uuid__']) {
			entityClass['__xhttp_uuid__'] = Uuid.generate();
		}
		if (!this._services[entityClass['__xhttp_uuid__']]) {
			this.createService(entityClass);
		}
		return this._services[entityClass['__xhttp_uuid__']];
	}
}

export default new XHTTPManager();