import {GenericService} from '@/shared/xhttp';
import {User} from '@/models';

export class UserService extends GenericService<User> {
	
	public getMe(): Promise<User> {
		return this.get('/me');
	}
	
	public async patchMeResetPasswordRequest(email: string): Promise<void> {
		await this.patch(`/me/reset-password-request/${email}`);
	}
}