import {Media} from "./Media";
import {XHTTP} from "@/shared/xhttp";
import {UploadService} from "@/xhttp";

@XHTTP(UploadService, '/uploads')
export class Upload {

    private _uuid: string;
    private _data: string;
    private _originalFileName: string;
    private _endOfFile: boolean;
    private _media: Media;

    public constructor() {
        this._media = new Media();
    }

    /////////////
    // Getters //
    /////////////

    public get uuid(): string {
        return this._uuid;
    }

    public get data(): string {
        return this._data;
    }

    public get originalFileName(): string {
        return this._originalFileName;
    }

    public get endOfFile(): boolean {
        return this._endOfFile;
    }

    public get media(): Media {
        return this._media;
    }

    /////////////
    // Setters //
    /////////////

    public set uuid(value: string) {
        this._uuid = value;
    }

    public set data(value: string) {
        this._data = value;
    }

    public set originalFileName(value: string) {
        this._originalFileName = value;
    }

    public set endOfFile(value: boolean) {
        this._endOfFile = value;
    }

    public set media(value: Media) {
        this._media = value;
    }

    ///////////////
    // Serialize //
    ///////////////

    public toJSON(): any {
        return {
            uuid            : this._uuid,
            data            : this._data,
            originalFileName: this._originalFileName,
            endOfFile       : this._endOfFile,
            media           : this._media.toJSON(),
        };
    }

    public fromJSON(data: any): this {
        this._uuid             = data.uuid;
        this._data             = data.data;
        this._originalFileName = data.originalFileName;
        this._endOfFile        = data.endOfFile;
        this._media            = data.media ? (new Media()).fromJSON(data.media) : null;
        return this;
    }
}
