




































import {Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {DateHelper} from '@/shared/utils';

@Component()
export default class InputDatePicker extends Vue {
	@InOut({type: Date   , isVModel: true   }) private value!     : Date;
	@InOut({type: Boolean, default: false   }) private open!      : boolean;
	@Prop ({type: String , default: ''      }) private label!     : string;
	@Prop ({type: Array  , default: () => []}) private rules!     : ((v?: any) => boolean|string)[];
	@Prop ({type: Boolean, default: false   }) private clearable! : boolean;
	@Prop ({type: Boolean, default: false   }) private dense!     : boolean;
	@Prop ({type: Number , default: 1       }) private errorCount!: number;
	@Prop ({type: Date   , default: null    }) private min!       : Date;
	@Prop ({type: Date   , default: null    }) private max!       : Date;
	
	public date: string = DateHelper.toFormattedDate(new Date());
	public formattedDate: string = '';
	
	public mounted(): void {
		this.refreshFormattedDate();
		if (this.value) {
			this.date = this.value.toISOString().substr(0, 10);
		}
	}
	
	public refreshFormattedDate(): void {
		this.formattedDate = DateHelper.toDisplayDate(this.value);
	}
	
	private parseDate(): void {
		const value = DateHelper.parseDisplayDate(this.formattedDate);
		if (value ? value.getTime() : null !== this.value ? this.value.getTime() : null) {
			this.value = value;
		}
		this.date = DateHelper.toFormattedDate(new Date());
		if (this.value) {
			this.date = DateHelper.toFormattedDate(this.value);
		}
	}
	
	public closePicker(): void {
		const value = new Date(this.date);
		if (value ? value.getTime() : null !== this.value ? this.value.getTime() : null) {
			this.value = value;
		}
		this.open = false;
		this.refreshFormattedDate();
	}
}
