







































import {Emit} from 'vue-property-decorator';
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Client} from '@/models';

import CrudClientCreate from '@/components/Crud/Client/Create.vue';

@Component({
	components: {
		CrudClientCreate,
	}
})
export default class CrudClientEditDialog extends Vue {
	@InOut({ type: Client  , isVModel: true }) private value!: Client;
	@InOut({ type: Boolean  , isVModel: false }) private route!: Boolean;

	@Emit()
	private onSubmit(client: Client) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
