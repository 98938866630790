import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorFilterState,
	PaginatorActionWithFilters,
	PaginatorMutation,
	StandardAction, SetterMutation,
} from '@/shared/utils';
import { Commercial } from '@/models';
import {CommercialService} from "@/xhttp";

import {DateFilter} from '@/shared/filter';
import store from '@/stores';

Vue.use(Vuex);

export class CommercialFilter {

	date: string = '';
	boisType: string = '';
	longueursCommercial: string = '';
	volume: string = '';
	prixStere: string = '';
	transporteur: string = '';
	facture: string = '';
	address: string = '';

	public clear(): void {
		this.date = '';
		this.boisType = '';
		this.longueursCommercial = '';
		this.volume = '';
		this.prixStere = '';
		this.transporteur = '';
		this.facture = '';
		this.address = '';
	}

	public toString(): string {
		return JSON.stringify({
			address	: this.address,
		});

	}
}

class CommercialState extends PaginatorFilterState<CommercialFilter> {
	commercial: Commercial = null;
	commercials: ResultType<Commercial> = null;
	dossier: Array<any> = [];
	public constructor() {
		super(new CommercialFilter(), 'commercials', 'commercial', 'date', AscDesc.DESC, 20)
	}
}

class CommercialStore {

	@XHTTPService(() => Commercial)
	private commercialService: CommercialService;

	public state: CommercialState = new CommercialState();

	public mutations = {
		...PaginatorMutation(() => storeCommercial, 'commercials', 'commercial'),
		...SetterMutation(['dossier']),
	};

	public actions = {
		...StandardAction<Commercial, CommercialState>(() => Commercial, 'commercial'),
		...PaginatorActionWithFilters<Commercial, CommercialState>(() => Commercial, 'commercials', 'commercial'),


		async repertoireCommercialSearch(context: ActionContext<CommercialState, any>, id: number): Promise<Commercial> {
			const commercial = await instance.commercialService.repertoireCommercialSearch(id);
			context.commit('setDossier', commercial);
			return commercial;
		}
	};

}

const instance = new CommercialStore();
const storeCommercial = new Vuex.Store(instance);
store.registerModule('commercial', storeCommercial);
export default storeCommercial;
