export default [
	{
		path: '/devis',
		name: 'devis',
		meta: {
			title: 'Liste des devis',
			granted: [ 'API_GET_DEVIS' ],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Devis.vue')
	},
];
