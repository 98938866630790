import {XHTTP} from '@/shared/xhttp';
import {InterlocuteurService} from "@/xhttp/InterlocuteurService";
import {FonctionInterlocuteur} from "@/models/FonctionInterlocuteur";
import {Client} from "@/models/Client";

@XHTTP(InterlocuteurService, '/interlocuteurs')
export class Interlocuteur {

	private _id: number;
	private _client: Client;
	private _fonctionInterlocuteur: FonctionInterlocuteur;
	private _strTitre: string;
	private _strNom: string;
	private _strTel: string;
	private _strMobile: string;
	private _strFax: string;
	private _strEmail: string;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get client(): Client {
		return this._client;
	}

	public get fonctionInterlocuteur(): FonctionInterlocuteur {
		return this._fonctionInterlocuteur;
	}

	public get strTitre(): string {
		return this._strTitre;
	}

	public get strNom(): string {
		return this._strNom;
	}

	public get strTel(): string {
		return this._strTel;
	}

	public get strMobile(): string {
		return this._strMobile;
	}

	public get strFax(): string {
		return this._strFax;
	}

	public get strEmail(): string {
		return this._strEmail;
	}

	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set client(value: Client) {
		this._client = value;
	}

	public set fonctionInterlocuteur(value: FonctionInterlocuteur) {
		this._fonctionInterlocuteur = value;
	}

	public set strTitre(value: string) {
		this._strTitre = value;
	}

	public set strNom(value: string) {
		this._strNom = value;
	}

	public set strTel(value: string) {
		this._strTel = value;
	}

	public set strMobile(value: string) {
		this._strMobile = value;
	}

	public set strFax(value: string) {
		this._strFax = value;
	}

	public set strEmail(value: string) {
		this._strEmail = value;
	}

	///////////////
	// Serialize //
	///////////////+

	public toJSON(): any {
		return {
			id       : this._id,
			strTitre       : this._strTitre,
			strNom       : this._strNom,
			strTel       : this._strTel,
			strMobile       : this._strMobile,
			strFax       : this._strFax,
			strEmail       : this._strEmail,
			client    : this._client ? this._client.cleclient : null,
			fonctionInterlocuteur    : this._fonctionInterlocuteur ? this._fonctionInterlocuteur : null,
		};
	}

	public fromJSON(data: any): this {
		this._id       = data.dwIDInterlocuteur;
		this._client     = data.client ? (new Client()).fromJSON(data.client) : null;
		this._fonctionInterlocuteur     = data.fonctionInterlocuteur ? (new FonctionInterlocuteur()).fromJSON(data.fonctionInterlocuteur) : null;
		this._strTitre        = data.strTitre;
		this._strNom        = data.strNom;
		this._strTel        = data.strTel;
		this._strMobile        = data.strMobile;
		this._strFax        = data.strFax;
		this._strEmail        = data.strEmail;
		return this;
	}
}