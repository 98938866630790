import storeToken from '@/stores/modules/token';

export class Granted {

	public firstPageLoaded(): boolean {
		return storeToken.state.firstPageLoaded;
	}

	public isConnected(): boolean {
		return !!storeToken.state.token;
	}

	/**
	 * Test if rights list is valid in token
	 * @param rights Can be string if once right and if null check if connected
	 */
	public isGranted(rights: string|string[]|boolean = null): boolean {

		if (rights === true) {
			return this.isConnected();
		}
		if (rights === false) {
			return true;
		}
		
		if (!!storeToken.state.token) {
			if (rights === null || rights.length === 0) {
				return true;
			}
			if (typeof rights === 'string') {
				rights = [ rights ];
			}
			return (<string[]>rights).filter(
				right => storeToken.state.token.rights.indexOf(right) !== -1
			).length === rights.length;
		}
		return false;
	}

	public checkRouteGranted(route: any): boolean {
		if (route && route.meta && route.meta.granted) {
			if (!route.meta.granted.length) {
				return this.isConnected();
			}
			if (!this.isGranted(route.meta.granted)) {
				return false;
			}
		}
		return true;
	}

}

export default new Granted();
