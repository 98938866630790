import Time from 'gollumts-time';
import {ObjectConstructor} from 'gollumts-objecttype';
import {ActionContext, Store} from 'vuex';
import xhttp, {AscDesc, ResultType} from '@/shared/xhttp';

export abstract class PaginatorState<E = any> {
	public listName: string;
	public currentName: string;
	public order: string;
	public direction: AscDesc = AscDesc.ASC;
	public page: number = 0;
	public limit: number = 10;

	public get current(): E{
		return this[this.currentName];
	}

	public get list(): ResultType<E> {
		return this[this.listName];
	}

	public get all(): ResultType<E> {
		return this[this.listName+'All'];
	}

	public set current(value: E){
		this[this.currentName] = value;
	}

	public set list(value: ResultType<E>) {
		this[this.listName] = value;
	}

	public set all(value: ResultType<E>) {
		this[this.listName+'All'] = value;
	}

	public constructor(
		listName: string,
		currentName: string = null,
		order: string = 'id',
		direction: AscDesc = AscDesc.ASC,
		limit: number = 10
	) {
		this.listName = listName;
		this.currentName = currentName;
		this.order = order;
		this.direction = direction;
		this.limit = limit;
	}
}

export abstract class PaginatorFilterState<F = any, E = any> extends PaginatorState<E> {
	public filters: F;

	public constructor(
		filters: F,
		listName: string,
		currentName: string = null,
		order: string = 'id',
		direction: AscDesc = AscDesc.ASC,
		limit: number = 10
	) {
		super(listName, currentName, order, direction, limit);
		this.filters = filters;
	}
}

export const PaginatorMutation = <E = any, PS extends PaginatorState = PaginatorState>(
	store: () => Store<PS>,
	listName: string,
	currentName: string = null,
): any => {
	const actions = {
		setOrder    (state: PaginatorState, order:     string       ) { const change = state.order     !== order;     state.order     = order;     if (change) store().dispatch('getC', { filters: true })},
		setDirection(state: PaginatorState, direction: AscDesc      ) { const change = state.direction !== direction; state.direction = direction; if (change) store().dispatch('getC', { filters: true })},
		setPage     (state: PaginatorState, page:      number       ) { const change = state.page      !== page;      state.page      = page;      if (change) store().dispatch('getC', { filters: true })},
		setLimit    (state: PaginatorState, limit:     number       ) { const change = state.limit     !== limit;     state.limit     = limit;     if (change) store().dispatch('getC', { filters: true })},
	};
	if (currentName) {
		actions['setCurrent']                                             = (state: PaginatorState, current: E) => { state.current = current };
		actions['set'+currentName[0].toUpperCase()+currentName.substr(1)] = (state: PaginatorState, current: E) => { state[currentName] = current };
	}
	actions['setList']                                          = (state: PaginatorState, list: ResultType<E>) => { state.list = list };
	actions['set'+listName[0].toUpperCase()+listName.substr(1)] = (state: PaginatorState, list: ResultType<E>) => { state[listName] = list };
	actions['setAll']                                                 = (state: PaginatorState, all: ResultType<E>) => { state.all = all };
	actions['set'+listName[0].toUpperCase()+listName.substr(1)+'All'] = (state: PaginatorState, all: ResultType<E>) => { state[listName+'All'] = all };
	return actions;
};


export const PaginatorMutationFilter = <F = any, E = any>(
	store: () => Store<PaginatorFilterState<E>>,
	listName: string,
	currentName: string = null,
): any => {
	return {
		...PaginatorMutation(store, listName, currentName),
		...{
			setFilters(state: PaginatorFilterState, filter: F) { state.filters = filter; }
		}
	}
};

export type PaginatorActionType<E extends any = any, S extends PaginatorState = PaginatorState> = {
	getC: (context: ActionContext<S, any>, options: any) => Promise<ResultType<E>>,
	getAll: (context: ActionContext<S, any>, options: any) => Promise<ResultType<E>>,
	get: (context: ActionContext<S, any>, id: number) => Promise<E>,
};

/**
 * Timeout une action du store
 * Et protege des double call en cas de changement de plusieurs paramètre
 */
export const actionTimeout = <S, R>(callback: (context: ActionContext<S, any>, options: any) => Promise<R>): ((context: ActionContext<S, any>, options: any) => Promise<R>) => {
	let countCall: number = 0;
	let lastPromise: Promise<R> = null;
	return (context: ActionContext<S, any>, options: any): Promise<R> => {
		const caller = async (): Promise<R> => {
			const callId = ++countCall;
			await Time.timeout(100);
			if (callId === countCall) {
				return callback(context, options);
			}
			return lastPromise;
		};
		lastPromise = caller();
		return lastPromise;
	};
};

export const PaginatorAction = function<E extends any = any, S extends PaginatorState = PaginatorState>(
	type: () => ObjectConstructor<E>,
	listName: string,
	currentName: string = null,
	queryCallback: (context?: ActionContext<S, any>, params?: any) => any = () => {},
): PaginatorActionType<E, S> {

	let firstCallPromise: Promise<ResultType<E>> = null;
	let firstCallResolve: (value: ResultType<E>) => void = null;
	let firstCallReject: Function = null;
	let callCounter: number = 0;
	
	return {
		
		getC: actionTimeout<S, ResultType<E>>(
			async (context: ActionContext<S, any>, options: any): Promise<ResultType<E>> => {

				const promise  = new Promise<ResultType<E>>(async (resolve, reject) => {

					if (!firstCallResolve) {
						firstCallResolve = resolve;
						firstCallReject = reject;
					}

					callCounter++;
					const id = callCounter;

					try {
						const resultData = await xhttp.get(type()).getC(
							'',
							options && typeof options.limit !== 'undefined' ? options.limit : context.state.limit,
							options && typeof options.page !== 'undefined' ? options.page : context.state.page,
							options && typeof options.order !== 'undefined' ? options.order : context.state.order,
							options && typeof options.direction !== 'undefined' ? options.direction : context.state.direction,
							queryCallback ? queryCallback(context, options) : {}
						);

						if (id === callCounter) {
							context.commit('set'+listName[0].toUpperCase()+listName.substr(1), resultData);
							firstCallPromise = null;
							firstCallResolve(context.state[listName]);

							firstCallPromise = null;
							firstCallResolve = null;
							firstCallReject = null;
						}
						resolve(context.state[listName]);
					} catch (e) {
						if (id === callCounter) {
							firstCallReject(e);
							firstCallPromise = null;
							firstCallResolve = null;
							firstCallReject = null;
						}
						reject(e);
					}
				});
				if (!firstCallPromise) {
					firstCallPromise = promise;
				}
				await promise;
				return await firstCallPromise;
			}
		),

		getAll: actionTimeout<S, ResultType<E>>(
			async (context: ActionContext<S, any>, options: any): Promise<ResultType<E>> => {
				const resultData = await xhttp.get(type()).getAll();
				context.commit('set'+listName[0].toUpperCase()+listName.substr(1)+'All', resultData);
				return context.state[listName+'All'];
			}
		),

		get: actionTimeout<S, E>(
			async (context: ActionContext<S, any>, id: number): Promise<E> => {
				const current = await xhttp.get(type()).get('/'+id);
				if (currentName) {
					context.commit('set'+currentName[0].toUpperCase()+currentName.substr(1), current);
				}
				return context.state[currentName];
			}
		),
	};
};

export const PaginatorActionWithFilters = function<E extends any = any, S extends PaginatorState = PaginatorState>(
	type: () => ObjectConstructor<E>,
	listName: string,
	currentName: string = null,
	queryCallback: (context?: ActionContext<S, any>, params?: any) => any = () => {},
): PaginatorActionType<E, S> {
	return PaginatorAction<E, S>(
		type,
		listName,
		currentName,
		(context?: ActionContext<S, any>, options: any = {}) => {
			const query = queryCallback ? queryCallback(context, options) : {};
			return {
				...query,
				...options.filters ? {filters: options.filters === true ? context.state['filters'] : options.filters} : {}
			};
		}
	);
};
