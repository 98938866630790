export * from './Form';
export * from './form.directive';

import Vue from 'vue';
import FormError from './FormError.vue';
import formDirective from './form.directive';

Vue.use({
	install(Vue: any, options: any) {
		Vue.component('form-error', FormError);
		Vue.directive('form', formDirective);
	}
});