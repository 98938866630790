















import {InOut} from 'vue-inout-prop-decorator';
import {Emit, Prop} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {Country} from '@/models';

import storeCountry from '@/stores/modules/country';
import storeLoader from '@/stores/modules/loader';

@Component()
export default class InputCountry extends Vue {
	
	@InOut({type: Country, isVModel: true   }) private value!     : Country;
	
	countries: Country[] = [];
	
	private loading = false;
	private searchValue: string = '';
	
	private get search(): string {
		return this.searchValue;
	}
	
	private set search(value: string) {
		if (this.searchValue !== value) {
			this.refresh(value);
		}
		this.searchValue = value;
	}
	
	public async mounted(): Promise<void> {
		if (!this.value) {
			this.value = await storeCountry.dispatch('getDefault');
		}
		this.searchValue = this.value.name;
		this.countries = [ this.value ];
	}
	
	@Emit()
	input(value: string): Country {
		for (const country of this.countries) {
			if (country.name === value) {
				return country;
			}
		}
		return null;
	}
	
	private async refresh(searchTerm: string): Promise<void> {
		storeLoader.commit('disable');
		this.loading = true;
		this.countries = (await storeCountry.dispatch('search', searchTerm)).data;
		storeLoader.commit('enable');
		this.loading = false;
	}
	
}
