import {GenericService, XHTTP} from '@/shared/xhttp';

@XHTTP(GenericService, '/roles')
export class Role {
	
	private _name: string;
	
	/////////////
	// Getters //
	/////////////
	
	public get name(): string {
		return this._name;
	}
	
	/////////////
	// Setters //
	/////////////
	
	public set name(value: string) {
		this._name = value;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			name: this._name,
		};
	}

	public fromJSON(data: any): this {
		this._name = data.name;
		return this;
	}
}