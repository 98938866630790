import {Expedition} from '@/models';

export default [
	{
		path: '/expedition',
		name: 'expeditions',
		meta: {
			title: 'Liste des expeditions',
			granted: [ 'API_GET_EXPEDITION' ],
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Expedition.vue')
	},
	{
		path: '/expedition/repertoire/:id',
		name: 'expedition-repertoire',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Expedition/ShowRepertoire.vue'),
				class: Expedition
			}),
		},
		meta: {
			title: 'Détail d\'un expedition',
			granted: [ 'API_GET_EXPEDITION' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/expedition/edit/:id',
		name: 'expedition-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Commercial/Edit.vue'),
				class: Expedition
			}),
		},
		meta: {
			title: 'Edition d\'un expedition',
			granted: [ 'API_PUT_EXPEDITION' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
