import {ObjectString} from 'gollumts-objecttype';
import {Component as ComponentBase, Vue as VueBase} from 'vue-property-decorator';
import {ComponentOptions as ComponentOptionsBase} from 'vue';
import {ObjectEvented, Uuid} from '@/shared/utils';
import granted from "@/shared/granted";
import storeNotifier, {NotifyInterface, NotifyType} from "@/stores/modules/notifier";

export interface ComponentOptions<V extends Vue = any> extends ComponentOptionsBase<V> {
	preloadFiles?: string[];
	waitChildMounted?: boolean;
}

export function Component<V extends Vue>(options?: ComponentOptions<V> & ThisType<V>): any {
	const called = ComponentBase(options || {});

	return function (...args: any): any {
		const target = args[0];
		const old = target.prototype['mounted'];

		target.prototype['mounted'] = async function () {

			this['promiseFileLoaded'] = new Promise(async (resolve, reject) => {
				try {
					if (options && options.preloadFiles) {
						await this.loadImages(options.preloadFiles);
					}
					resolve();
				} catch (e) {
					reject(e);
				}
			});
			this['promiseMounted'] = new Promise(async (resolve, reject) => {
				try {
					if (old) {
						await old.apply(this);
					}
					resolve();
				} catch (e) {
					reject(e);
				}
			});

			const waitChildMounted = async () => {
				if (options && options.waitChildMounted) {
					await this.waitChildMounted();
				}
			};

			await Promise.all([
				waitChildMounted(),
				this['promiseFileLoaded'],
				this['promiseMounted'],
			]);

			this['isMounted'] = true;
		};
		return called.apply(null, args);
	};
}

export abstract class Vue extends VueBase {

	private static imagesLoaded: ObjectString<Promise<void>> = {};

	public componentId: string = Uuid.generate();
	public isMounted: boolean = false;
	public promiseFileLoaded: Promise<void> = null;
	public promiseMounted: Promise<void> = null;

	public isGranted(rights: string|string[]|boolean): boolean {
		return granted.isGranted(rights);
	}

	public noContainer(): void {
		document.body.classList.add('no-container');
		this.$once('hook:beforeDestroy', () => {
			document.body.classList.remove('no-container');
		});
	 }

	public async waitChildMounted(): Promise<void> {
		await Promise.all(
			this.$children.map(child => {
				if (child['waitChildMounted']) {
					return child['waitChildMounted'];
				}
			})
		);
	}

	protected async loadImages(files: string[]): Promise<void> {
		await Promise.all(
			files.map(src => {
				if (!Vue.imagesLoaded[src]) {
					Vue.imagesLoaded[src] = new Promise((resolve => {
						console.log('Preload:', src)
						const image = new Image();
						image.onload = () => resolve();
						image.onerror = e => {
							console.error('Error on load:', src, e);
							resolve();
						};
						image.src = src;
					}));
				}
				return Vue.imagesLoaded[src];
			})
		);
	}

	protected addEventObject(object: ObjectEvented, event: string, callback: Function, dropOnFirst: boolean = false): void {
		const id = object.addListener(event, callback, dropOnFirst);
		this.$once('hook:beforeDestroy', () => {
			object.removeListener(id);
		});
	}

	protected addEventDom(object: Window|Node, event: string, callback: Function, dropOnFirst: boolean = false): void {
		let dropped = false;
		const cb = (...args: any[]) => {
			callback(...args);
			if (dropOnFirst) {
				object.removeEventListener(event, cb);
				dropped = true;
			}
		};
		object.addEventListener(event, cb);
		this.$once('hook:beforeDestroy', () => {
			if (!dropped) {
				object.removeEventListener(event, cb);
			}
		});
	}

	protected notify(message: string, type: NotifyType = 'success', timeout: number = 5000) {
		storeNotifier.dispatch('notify', {
			type: type,
			message: this.$t(message),
			timeout: timeout
		} as NotifyInterface);
	}

}
