








































































































































































































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Prospection, Token, Client} from '@/models';
import storeProspection from '@/stores/modules/prospection';

import InputPhone from '@/components/Input/Phone.vue';
import InputCountry from "@/components/Input/Country.vue";
import InputZipCode from "@/components/Input/ZipCode.vue";
import storeClient from '@/stores/modules/client';
import InputDatePicker from "@/components/Input/DatePicker.vue";
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxAutocomplete } from 'devextreme-vue/autocomplete';
import CrudClientCreateDialog from "@/components/Crud/Client/CreateDialog.vue";
import storeToken from "@/stores/modules/token";
import {Stored} from "vue-stored-prop-decorator";
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
import {
	DxDataGrid,
	DxColumn,
	DxEditing,
	DxFilterRow,
	DxHeaderFilter,
	DxGroupPanel,
	DxGrouping,
	DxScrolling,
	DxSummary,
	DxLookup,
	DxTotalItem,
	DxGroupItem,
	DxMasterDetail,
	DxStringLengthRule,
	DxRequiredRule,
	DxRangeRule,
	DxValueFormat,
	DxPaging,
	DxPager,
} from 'devextreme-vue/data-grid';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { createStore } from 'devextreme-aspnet-data-nojquery';

@Component({
	components: {
		DxAutocomplete,
		DxSelectBox,
		InputPhone,
		InputCountry,
		InputZipCode,
		InputDatePicker,
		CrudClientCreateDialog,
		DxLoadIndicator,
		DxDataGrid,
		DxColumn,
		DxEditing,
		DxFilterRow,
		DxHeaderFilter,
		DxGroupPanel,
		DxGrouping,
		DxScrolling,
		DxSummary,
		DxLookup,
		DxTotalItem,
		DxGroupItem,
		DxMasterDetail,
		DxStringLengthRule,
		DxRangeRule,
		DxRequiredRule,
		DxValueFormat,
		DxPaging,
		DxPager,
		DxCheckBox,
	}
})
export default class CrudProspectionEdit extends AbstractEditVue<Prospection> {

	@Stored(() => storeToken) token!: Token;

	// DF: utiliser pour la création d'un prospection uniquement
	// en modification on passe par la light
	//private step: number = 1;
	private editClient: Client = null;
	//private saveDisabled = true;
	private storeClient = storeClient;
	//private SelectedCleclient: string = null;

	private step: number = 1;
	private isNew = true;
	private isLoaded = true;
      private searchModeOption = 'contains';
      private searchExprOption = 'raisonsoc';
      private searchTimeoutOption = 4000;
      private minSearchLengthOption = 3;
      private showDataBeforeSearchOption = false;

	/*private url = process.env.VUE_APP_BACK_BASE_URL + '/api/clients'
	private dataGrid;

	private clients = createStore({
		key: 'raisonsoc',
		loadUrl: `${this.url}/selection`,
		onBeforeSend: (method, ajaxOptions) => {
			this.isLoaded = false;
			ajaxOptions.xhrFields = { withCredentials: true };
			ajaxOptions.headers = {
				'Authorization': 'BEARER ' + this.token.id}
			},
	});*/

	private url = process.env.VUE_APP_BACK_BASE_URL + '/api/clients'
	private dataGrid;

	private dataSource = createStore({
		key: 'cleclient',
		loadUrl: `${this.url}/fetch`,
		onBeforeSend: (method, ajaxOptions) => {
			ajaxOptions.xhrFields = { withCredentials: true };
			ajaxOptions.headers = {
				'Authorization': 'BEARER ' + this.token.id
			}
		},
	});

	public constructor() {
		super(
			storeProspection,
			'Prospection modifié',
			'Prospection créé',
		);
	}

	protected async onSubmit(): Promise<void> {
		//if (this.item) {
		//this.item.dwIDClient = this.SelectedCleclient;
		//}
		super.onSubmit();
		//this.step = 1;
	}

		@Emit()
	private async onNext(cleclient) {
			this.step = 2;
			await storeClient.dispatch('get', cleclient);
			this.item.dwIDClient = parseInt(cleclient);
      		console.log(cleclient);
		}

	public async mounted(): Promise<void> {
		await super.mounted();
		this.item = this.value;
		if (this.item.id > 0)
		{
			this.step = 2;
			this.isNew = false;
			await storeClient.dispatch('get', this.item.dwIDClient);
		}
		else 
		{
			console.log(this.item);
			if (this.item.dwIDClient > 0)
			{
				console.log("New avec client");
				console.log(this.item.dwIDClient);
				this.step = 2;
				this.isNew = true;
				await storeClient.dispatch('get', this.item.dwIDClient);
			}
		}
		await super.mounted();
	}

	public async refresh(): Promise<void> {
		await super.refresh();
		this.$forceUpdate();
	}

	public newClient() {
		this.editClient = new Client();
	}

}
