import {XHTTP} from '@/shared/xhttp';
import {AddressService} from '@/xhttp';
import {Country} from './Country';
import {Expedition} from "@/models/Expedition";
import {DateHelper} from "@/shared/utils";

@XHTTP(AddressService, '/addresss')
export class Address {

	private _id: number;
	private _name: string;
	private _address: string;
	private _zipCode: string;
	private _town: string;
	private _country: Country;
	private _expedition: Expedition;
	private _createdAt: Date;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get name(): string {
		return this._name;
	}

	public get address(): string {
		return this._address;
	}

	public get zipCode(): string {
		return this._zipCode;
	}

	public get town(): string {
		return this._town;
	}

	public get country(): Country {
		return this._country;
	}

	public get expedition(): Expedition {
		return this._expedition;
	}

	public get createdAt(): Date {
		return this._createdAt;
	}

	public get line1(): string {
		return ((this._name ? this._name : '') + ' ' + (this._address ? this._address : '')).trim();
	}

	public get line2(): string {
		return ((this.zipCode ? this.zipCode : '') + ' ' + (this._town ? this._town : '') + ' ' + (this.country ? this.country.name : '')).trim();
	}

	/////////////
	// Setters //
	/////////////

	public set name(value: string) {
		this._name = value;
	}

	public set address(value: string) {
		this._address = value;
	}

	public set zipCode(value: string) {
		this._zipCode = value;
	}

	public set town(value: string) {
		this._town = value;
	}

	public set country(value: Country) {
		this._country = value;
	}

	public set expedition(value: Expedition) {
		this._expedition = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id     : this._id,
			name   : this._name,
			address: this._address,
			zipCode: this._zipCode,
			town   : this._town,
			country: this._country ? this._country.toJSON() : null,
		};
	}

	public fromJSON(data: any): this {
		this._id        = data.id;
		this._name      = data.name;
		this._address   = data.address;
		this._zipCode   = data.zipCode;
		this._town      = data.town;
		this._country   = data.country ? (new Country()).fromJSON(data.country) : null;
		this._createdAt = DateHelper.fromLocalizedString(data.createdAt);
		this._expedition     = data.expedition ? (new Expedition()).fromJSON(data.expedition) : null;
		return this;
	}
}
