import {ObjectNumber, ObjectString} from 'gollumts-objecttype';

export class ObjectEvented {
	
	private _listeners: ObjectString<ObjectNumber<Function>> = {};
	private _listenersName: ObjectNumber<string> = {};
	private _listenersGlobalId: number = 0;
	
	public addListener(name: string, callback: Function, dropOnFirst: boolean = false): number {
		
		if (!this._listeners) { // FIX IE10
			this._listeners = {};
		}
		
		if (!this._listeners[name]) {
			this._listeners[name] = {};
		}
		const id = ++this._listenersGlobalId;
		this._listeners[name][id] = (...params: any[]) => {
			callback.apply(this, params);
			if (dropOnFirst) {
				this.removeListener(id);
			}
		};
		this._listenersName[id] = name;
		
		return id;
	}
	
	public removeListener(id: number): void {
		const name = this._listenersName[id];
		if (name && this._listeners[name] && this._listeners[name][id]) {
			delete this._listeners[name][id];
			delete this._listenersName[id];
		}
	}
	
	public removeAllListeners() {
		this._listeners = {};
		this._listenersName = {};
		this._listenersGlobalId = 0;
	}
	
	public async trigger(name: string, ...params: any[]): Promise<void> {
		if (this._listeners[name]) {
			await Promise.all(
				Object.keys(this._listeners[name]).map(
					i => this._listeners[name][i].apply(this, params)
				)
			);
		}
	}
}