export default (value: number, decimal: number = 2, fixed: boolean = true): string => {
	if (isNaN(value)) {
		return '';
	}
	value = Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);

	const full = fixed ? value.toFixed(decimal) : value.toString();
	let out = '';
	let temp = '';
	if (fixed) {
		out = ','+full.substr(full.length - decimal);
		temp = full.substr(0, full.length - decimal - 1);
	} else {
		const split = full.split('.');
		temp = split[0];
		out = split[1] ? ','+split[1] : '';
	}

	for (let i = 0; i < temp.length; i++) {
		const c = temp[temp.length - 1 - i];
		if (c !== '-' && i !== 0 && i % 3 === 0) {
			out = '\u00A0'+out;
		}
		out = c + out;
	}
	return out;
};
