import {GenericService} from '@/shared/xhttp';
import {Commercial} from '@/models';

export class CommercialService extends GenericService<Commercial> {

	public async repertoireCommercialSearch(id: number): Promise<Commercial> {
		const result = await this.put(`/repertoire/commercial/search/${id}`);
		return result;
	}

}