import {ObjectConstructor} from 'gollumts-objecttype';
import {Writer} from 'gollumts-annotation';
import {IService} from '../Services';

export interface XHTTPAnnotation {
	service: ObjectConstructor<IService>;
	url: string;
}

export const XHTTP = (service: ObjectConstructor<IService>, url: string) => Writer.write(XHTTP, {
	service: service,
	url: url
} as XHTTPAnnotation);
