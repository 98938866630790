import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {Country,} from '@/models';
import {CountryService} from '@/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation,
	SetterMutation
} from '@/shared/utils';
import {FunctionHelper} from '@/shared/utils/FunctionHelper';
import store from '@/stores';


Vue.use(Vuex);

export class CountryFilter {

	name: string = '';

	public toString(): string {
		return JSON.stringify(this);
	}
}

class CountryState extends PaginatorFilterState<CountryFilter> {
	default: Country = null;
	countries: ResultType<Country> = null;
	filters: CountryFilter = new CountryFilter();
	public constructor() {
		super(new CountryFilter(), 'countries');
	}
}

class CountryStore {

	@XHTTPService(() => Country)
	private countryService: CountryService;

	public state: CountryState = new CountryState();

	public mutations = {
			...PaginatorMutation(() => storeCountry, 'countries'),
			...SetterMutation(['default']),
	};

	public actions = {

		...PaginatorActionWithFilters<Country, CountryState>(() => Country, 'countries'),

		getByName(context: ActionContext<CountryState, any>, name: string): Promise<Country> {
			return instance.countryService.get(`/name/${name}`);
		},

		getDefault: FunctionHelper.timeoutAsync(
			async (context: ActionContext<CountryState, any>, searchTerm: string): Promise<Country> => {
				if (!context.state.default) {
					context.commit('setDefault', await context.dispatch('getByName','France'));
				}
				return context.state.default;
			}
		),

		search(context: ActionContext<CountryState, any>, searchTerm: string): Promise<ResultType<Country>>	 {
			context.state.filters.name = searchTerm;
			context.commit('setPage', 0);
			context.commit('setLimit', 10);
			context.commit('setOrder', 'name');
			context.commit('setDirection',  AscDesc.ASC);
			return context.dispatch('getC', { filters: true });
		},
	};

}
const instance = new CountryStore();
const storeCountry = new Vuex.Store(instance);
store.registerModule('country', storeCountry);
export default storeCountry;
