

































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Client, Token} from '@/models';

import storeClient from '@/stores/modules/client';

import InputDatePicker from "@/components/Input/DatePicker.vue";
import axios from "axios";
import {Stored} from "vue-stored-prop-decorator";
import storeToken from "@/stores/modules/token";

@Component({
	components: {
		InputDatePicker,
	}
})
export default class CrudClientEdit extends AbstractEditVue<Client> {

	// DF: utiliser pour la création d'un client uniquement
	// en modification on passe par la light
	@Stored(() => storeToken) private token: Token;
	private number: number = null;

	private clientYsofer = [];

	public constructor() {
		super(
			storeClient,
			'Client modifié',
			'Client créé',
		);
	}

	//	@Emit()
	//	private onNext() {
	//		if ((!this.item.address) || (this.item.address === null) )
	//		{
	//			this.item.address = this.item.name;
	//		}
	//		this.step = 2;
	//	}

	protected async onSubmit(): Promise<void> {
		this.$emit('on-submit', this.number);
	}

	public async mounted(): Promise<void> {
		axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/users/fetch/clientsYso" + '?token=' + this.token.id, {}).then(response => {
			this.clientYsofer = response.data;
		});
		await super.mounted();
	}

	public async refresh(): Promise<void> {
		await super.refresh();
		//console.log('route client edit = ' + this.$route.name);
	}

}
