import {GenericService} from '@/shared/xhttp';
import {Token, TokenLogin} from '@/models';

export class TokenService extends GenericService<Token> {
	
	public async login(tokenLogin : TokenLogin) : Promise<Token> {
		return this.post('/login', tokenLogin);
	}
	
	public async refresh() : Promise<Token> {
		return this.patch('/refresh');
	}
}